import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input } from '@angular/core';

import { Content, ContentType_T, Comment } from './../../apiService/classFiles/class.content';
import { Organization, PartnerCustomerTenantInfo, Tenant } from './../../apiService/classFiles/class.organizations'
import { ProdGenApi } from './../../apiService/prodgen.api';
import { AppComponent } from '../../app.component';
import { Apiv2Service } from 'src/app/apiService/apiv2.service';

declare var $: any;

@Component({
  selector: 'template-clientAccounts',
  templateUrl: './clientlist.component.html',
  styleUrls: ['./clientlist.component.css'],
  providers: [ProdGenApi],
})
export class ClientList implements OnInit {

  orgSet: Organization[];
  tenantSet: Tenant[];


  allCustomers: Array<PartnerUsers> = new Array<PartnerUsers>();
  filteredCustomers: Array<PartnerUsers> = new Array<PartnerUsers>();
  curDate: Date = new Date(Date.now());
  filterText: string = "";
  sortNameDir: number = 1;
  sortIDDir: number = 1;
  sortAltDir: number = 1;
  sortExpDir: number = 1;
  sortLiteDir: number = 1;

  isLoading: boolean = false;

  constructor(private service: ProdGenApi, private apiV2Service: Apiv2Service) { }

  ngOnInit() {

    /*
    this.service.getPartnerAccounts().subscribe(res => {
      this.orgSet = res;
      },
      err => {

      });
      */
    this.curDate = new Date(Date.now());

    this.apiV2Service.getPartnerTenantsV2().subscribe(partnerClients => {
        for (let partnerTenant of partnerClients) {
        let cust = new PartnerUsers();
        cust.name = partnerTenant.clientName || "";
        cust.orgId = partnerTenant.orgId ? partnerTenant.orgId.toString() : "";
        cust.altId = partnerTenant.altOrgId ? partnerTenant.altOrgId.toString() : "";
        cust.expiration = partnerTenant.expiration ? new Date(partnerTenant.expiration) : null;
        cust.isPinnacleLite = partnerTenant.isPinnacleLite;
        cust.tenantId = partnerTenant.tenantId;
        cust.isPeakEnabled = partnerTenant.isPeakEnabled;

        this.allCustomers.push(cust);
      }

      this.headerClick("NAME");
    });

  }

  filterTextChanged() {
      this.filteredCustomers.length = 0;

      if (this.filterText.length == 0) {
          for (let c of this.allCustomers) {
              this.filteredCustomers.push(c);
              // only load 100. should be using the filter
              if (this.filteredCustomers.length >= 100) {
                  break;
              }
          }
      return;
    }

      for (let c of this.allCustomers) {

          let filter = this.filterText.toLowerCase();
          if ((c.altId != null && c.altId.toLowerCase().indexOf(filter) >= 0) ||
            (c.name != null && c.name.toLowerCase().indexOf(filter) >= 0) ||
            (c.orgId != null && c.orgId.toLowerCase().indexOf(filter) >= 0) ||
            (c.expiration != null && c.expiration.getMonth().toString().toLowerCase().indexOf(filter) >= 0) ||
            (c.expiration != null && c.expiration.getDate().toString().toLowerCase().indexOf(filter) >= 0) ||
            (c.expiration != null && c.expiration.getFullYear().toString().toLowerCase().indexOf(filter) >= 0)
          ) {
              this.filteredCustomers.push(c);
              // only load 100. should be using the filter
              if (this.filteredCustomers.length >= 100) {
                  break;
              }

          }
      }

  }

  // Customer Header has been clicked. We need to sort the items. We keep track if we are sorting ascending or descending for each column
  headerClick(header: string) {
    if (header == "NAME") {
      this.sortAltDir = 1;
      this.sortExpDir = 1;
      this.sortIDDir = 1;
      this.sortLiteDir = 1;

      this.allCustomers = this.allCustomers.sort((r1, r2) => {
        if (r1.name > r2.name) {
          return 1 * this.sortNameDir;
        }
        else if (r1.name < r2.name) {
          return -1 * this.sortNameDir;
        }
        return 0;
      });
      this.sortNameDir *= -1;

    }
    else if (header == "ALT") {
      this.sortExpDir = 1;
      this.sortIDDir = 1;
      this.sortNameDir = 1;
      this.sortLiteDir = 1;

      this.allCustomers = this.allCustomers.sort((r1, r2) => {
        if (r1.altId > r2.altId) {
          return 1 * this.sortAltDir;
        } 
        else if (r1.altId < r2.altId) {
          return -1 * this.sortAltDir;
        }
        return 0;
      });

      this.sortAltDir *= -1;

    }
    else if (header == "PINNACLE_LITE") {
      this.sortAltDir = 1;
      this.sortExpDir = 1;
      this.sortIDDir = 1;
      this.sortNameDir = 1;

      this.allCustomers = this.allCustomers.sort((r1, r2) => {
        if (r1.isPinnacleLite < r2.isPinnacleLite) {
          return 1 * this.sortLiteDir;
        }
        else if (r1.isPinnacleLite > r2.isPinnacleLite) {
          return -1 * this.sortLiteDir;
        }
        return 0;
      });

      this.sortLiteDir *= -1;
    }
    else if (header == "EXP") {
      this.sortAltDir = 1;
      this.sortIDDir = 1;
      this.sortNameDir = 1;
      this.sortLiteDir = 1;

      this.allCustomers = this.allCustomers.sort((r1, r2) => {
        if (r1.expiration < r2.expiration) {
          return 1 * this.sortExpDir;
        }
        else if (r1.expiration > r2.expiration) {
          return -1 * this.sortExpDir;
        }
        return 0;
      });


      this.sortExpDir *= -1;
    }

    else if (header == "ID") {
      this.sortAltDir = 1;
      this.sortExpDir = 1;
      this.sortNameDir = 1;
      this.sortLiteDir = 1;

      this.allCustomers = this.allCustomers.sort((r1, r2) => {
        if (parseInt(r1.orgId) > parseInt(r2.orgId)) {
          return 1 * this.sortIDDir;
        }
        else if (parseInt(r1.orgId) < parseInt(r2.orgId)) {
          return -1 * this.sortIDDir;
        }
        return 0;
      });


      this.sortIDDir *= -1;
    }

    this.filterTextChanged();
  }


  orgClick(o: Organization) {
    this.service.getAccountTenants(o.organizationId.toString()).subscribe(res => {
      if (res.length > 0) {
        if (res.length > 1) {
          //open dialog for choice
          this.tenantSet = res;
          $("tenantModal").modal('show');
        }
        else {
          this.impersonate(o.organizationId.toString(), res[0], false);
        }
      }
      else {
        //message
      }
    });
  }

  partnerUserClick(e: PartnerUsers) {

	  let v_Tenant = new Tenant();
	  v_Tenant.tenantId = e.tenantId;
	  v_Tenant.isPinnacleLite = e.isPinnacleLite;
	  v_Tenant.expiration = e.expiration;
	  v_Tenant.organizationId = parseInt(e.orgId);
	  v_Tenant.altOrganizationId = e.altId;
	  v_Tenant.name = e.name;

	  this.impersonate(e.orgId, v_Tenant, e.isPeakEnabled);
  }

  impersonate(orgID: string, tenant: Tenant, isPeak: boolean) {
    //message
    this.isLoading = true;

    this.service.getAccountAccessKey(orgID, tenant.tenantId.toString()).subscribe(res => {

      if (res == '') return;

      // now call and get the V2 access key
      this.service.getAccountAccessKeyV2(orgID, tenant.tenantId.toString()).subscribe(apiV2Token => {

        ProdGenApi.setAPIV2BearerToken(apiV2Token);

        // clear tenantInformation in case of a redirect to admin browser
        localStorage.setItem('tenantInformation', null)

        if (isPeak) {

          // send v2 bearer token & lang values to Peak to bypass authorization
          // for tenants with peak experience enabled
          AppComponent.thisFromOutside.openPeak(
            '_self',
            {
              bt: apiV2Token.bearerToken,
              lang: this.service.getCurrentLanguage(),
              peakUrlPath: ''
            });
          sessionStorage.clear();

          return;
        }
        
        ProdGenApi.setUserAccessKey(res);
        ProdGenApi.setSessionTenant(tenant);
        ProdGenApi.setCurrentTenantId(tenant.tenantId.toString());
        sessionStorage.setItem("currentTenant", tenant.tenantId.toString());
        localStorage.setItem("lastUsedTenant", tenant.tenantId.toString());

        // Need to keep track of this status across
        // the reload so that we don't use your key
        // to try and perform actions as the impersonation user.
        ProdGenApi.setIsImpersonating(true);
        
        window.location.reload();

      });

    },
    err => {
      this.isLoading = false;
    });
  }

  tenantClick(t: Tenant) {
    this.impersonate(t.organizationId.toString(), t, false);
  }

 
}

export class PartnerUsers {
  orgId: string;
  tenantId: string;
  altId: string;
  name: string;
  users: number;
  expiration: Date;
  isPinnacleLite: boolean;
  isPeakEnabled: boolean;
}
