import { Component, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import cssVars from 'css-vars-ponyfill';
import { catchError, concatMap, share, take, tap, map } from 'rxjs/operators';
import { Apiv2Service } from '../apiService/apiv2.service';
import { GetKnowledgeSmartResponse, KnowledgeSmartConfiguration, KSInviteToAssessment } from '../apiService/classFiles/v2-organizations';
import { SharedService } from '../apiService/loginservice.service';
import { AppComponent, currenttheme, defpreviewtheme } from '../app.component';
import { WidgetLibraryService } from '../services/widget-library.service';
import { APIV2AccessKey, Language, Policy } from './../apiService/classFiles/class.authorization';
import { Tenant, Theme, Setting, PinnacleLiteSettings } from './../apiService/classFiles/class.organizations';
import { SettingType_T } from './../apiService/classFiles/class.users';
import { ProdGenApi } from './../apiService/prodgen.api';
import { TranslationService } from './../services/TranslationService';
import { hexToRgb } from '../colors';
import { forkJoin, of, Observable } from 'rxjs';
import { ContentUrlService } from '../services/content-url.service';
import { ContentType_T } from '../apiService/classFiles/class.content';
import { environment } from 'src/environments/environment';
import * as LogRocket from 'logrocket';

declare var $: any;
//declare const saml: any = require('samlify'); 

@Component({
	selector: 'login-screen',
	templateUrl: 'login.component.html',
  styleUrls: ['login.component.css'],
  providers: [ProdGenApi],
	moduleId: module.id
})
export class LoginComponent implements OnInit, AfterViewInit {	
    public email: string = "";
    public password: string = ""; 
    public tenants: Array<Tenant> = new Array<Tenant>();
    public authError: boolean = false;
    public emailError: boolean = false;
    public selectedTenantId: string = "";
    public selectedLanguage: string = "en";
	public languages: Array<Language> = new Array<Language>();
    public emailAuth: boolean = false;
    public signInLogo: string = "";         //this value will now get set in the SetLogo code
    public psSignInLogoLight: string = "../../assets/images/ps_logo.png";
    public psSignInLogoDark: string = "../../assets/images/ps_logo_dark.png";
    public emailValidated: boolean = false;

    public mobileDeviceCordova: boolean = false;

    public static staticSelectedLanguage: string = "en";

	ssoSetting: boolean = false;
	passwordPolicyEnforcedSetting: boolean = false;
	complexPasswordSetting: boolean = false;
	passwordExpiresSetting: boolean = false;
	passwordExpiresCount: number = 0;
	passwordDifferentSetting: boolean = false;
    passwordDiferentCount: number = 0;
  
	forgotPasswordVisible: boolean = false;

	storedKey: string = "";

	messageBoxTitle: string = "";
	messageBoxBody: string = "";
	messageBoxActionText: string = "";
	messageBoxNegativeText: string = "";
	messageBoxCancelText: string = "";

	rememberMe: boolean = false;
	rememberMeVisible: boolean = false;

	hideLogin: boolean = false;

	policyTitle: string = "";
	policyText: string = "";
	policyAccept: boolean = false;
	PolicyAcceptButtonText: string = "Accept";
	policyList: Array<Policy> = new Array<Policy>();
	policyCurrentNum: number = 1;
	policyTotalNum: number = 1;
	policyChecked: boolean = false;

	signInText: string = "Sign In";
	rememberMeText: string = "Remember Me";
    forgotPasswordText: string = "Forgot Password";
    isSSOUser: boolean = false;
    ssoSettingName: string = "";
	samlAuthError: boolean = false;

    tempUserAccessKey: string = "";
    tempAPIV2AccessKey: APIV2AccessKey = new APIV2AccessKey();
    tempRememberMeToken: string = "";

    previewtheme = defpreviewtheme;

    partnerOverrideSSO = false;

    KSConfigInfo: KnowledgeSmartConfiguration = new KnowledgeSmartConfiguration();
    rgb: any;

    redirectURL: string;
    contentRedirectUrl: string = '';

    constructor(private pinnacleService: ProdGenApi, private APIv2: Apiv2Service, private router: Router, private _sharedService: SharedService, private tranService: TranslationService, private _sanitizer: DomSanitizer, private route: ActivatedRoute, private WidgetLibSvc: WidgetLibraryService, private contentUrlService: ContentUrlService, private elementref: ElementRef) {

        if (environment.logRocketAppId && window.location.hostname !== 'localhost') {
            LogRocket.startNewSession();
        }
            
        if (sessionStorage.getItem("APIKey") && sessionStorage.getItem("userAccessKey") && sessionStorage.getItem("apiV2AccessKey")){
            this.router.navigate(['/home']);
        }


		    this.pinnacleService.getLanguages().subscribe(res => {
			    this.languages = res;
			    this.fillEmptyLanguageSet();
		    }, err => {
			    this.fillEmptyLanguageSet();
		    })

        this.selectedLanguage = "en";

        route.queryParams.subscribe(queryParams => {
            if (queryParams['hideLogin'] == "false") {
              this.hideLogin = false;
              this.samlAuthError = true;
            }
            if(queryParams['redirectURL']){
                this.redirectURL = queryParams['redirectURL'];
            }
            if (queryParams['contentRedirectUrl']) {
                this.contentRedirectUrl = queryParams['contentRedirectUrl'];
            }
            if (queryParams['ksInviteId'] && queryParams['psUserId'] && // Passed into Peak via session storage to allow links to KS Assessments to be passed through and opened in a new tab from Peak.
                this.isInt(queryParams['ksInviteId']) && this.isGuid(queryParams['psUserId'], false)) { 

                const ksAssessmentData: KSInviteToAssessment = { ksInviteId: parseInt(queryParams['ksInviteId']), psUserId: queryParams['psUserId'] };
                const data = { value: ksAssessmentData };
                localStorage.setItem('[peak]ksAssessmentToTake', JSON.stringify(data));
            }
        });

        _sharedService.selectedTenantId$.pipe(share()).subscribe(id => {
            this.checkSamlAuthentication(id);
        });
    }

    isInt(value: any): boolean {
        // From here: https://stackoverflow.com/a/14794066/7149145
        if (isNaN(value)) {
            return false;
        }
        const x = parseFloat(value);
        return (x | 0) === x;
    }

    isGuid(value: any, allowEmptyGuid: boolean): boolean {
        // From here: https://stackoverflow.com/a/13653180/7149145
        if (!value) {
            return false;
        }

        let pattern;

        if (allowEmptyGuid) {
            pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        }
        else {
            pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
        }

        return pattern.test(value);
    }

    onClick() {
        this._sharedService.emitChange('Data from child');
    }

	  fillEmptyLanguageSet() {
		    if (this.languages == null || this.languages.length == 0) {


			    let v_English = new Language();
			    v_English.languageCode = "en";
			    v_English.languageName = "English";

			    this.languages = [v_English];


		    }
	  }

    ngOnInit(): void {
        
      this.pinnacleService.GetApiToken("9d391065-2abe-4681-9ea3-a78557a42a13").subscribe(r => {
          ProdGenApi.setAPIKey(r as string);
          
          if (window.location.href.startsWith("http://localhost")
              || window.location.href.startsWith("https://prodgenbrowser")
              || window.location.href.startsWith("https://portalbeta")
              || ProdGenApi.getUserAccessKey.length == 0) {
                this.tranService.loadTranslationFileLocal().subscribe(dataFile => {

                    this.tranService.loadTranslationFileDataFromVariable(dataFile);
			              this.loadTranslatedText();
                },
                  err => {
                    console.log(err);
                  });
            }
            else {
              this.pinnacleService.getTranslatedFileFromLanguageCode("en").subscribe(dataFile => {

                  this.tranService.loadTranslationFileDataFromVariable(dataFile);
			              this.loadTranslatedText();
              },
                fileError => {
                  this.tranService.loadTranslationFileLocal().subscribe(dataFile => {

				              this.tranService.loadTranslationFileDataFromVariable(dataFile);
				              this.loadTranslatedText();
                  },
                    err => {
                        console.log(err);
                  });
                });
            }
              var currentDate = Date.now();

		          let url = "#"; 

              if (window.location.href.toLocaleLowerCase().includes("localhost")) {
                  document.title = "Pinnacle Series - Debug";
              }
              if (window.location.href.toLocaleLowerCase().includes("productivitynow.imaginit.com")) {
                  document.title = "IMAGINiT";
                  //this.signInLogo = "../../assets/images/signin_imaginit.png";        //the value for signInLogo is now set in the SetLogo logic
               }
              else if (window.location.href.toLocaleLowerCase().includes("productivitynow.ascented.com")) {
                  document.title = "Ascent";
                  //this.signInLogo = "../../assets/images/signin_ascent.png";          //the value for signInLogo is now set in the SetLogo logic
               }
              else if (window.location.href.toLocaleLowerCase().includes("productivitynow.rand3d.com")) {
                  document.title = "Rand 3D";
                  //this.signInLogo = "../../assets/images/signin_rand.png";            //the value for signInLogo is now set in the SetLogo logic
               }
 
		      //this.router.navigate(['/login']);
		      sessionStorage.setItem("ChatActive", "false");
		    sessionStorage.setItem("ChatID", "");
		

		

		      if (localStorage.getItem("rememberMe") != null)
		      {
			      if (localStorage.getItem("rememberMe") == "true")
			      {
				      //localStorage.setItem("rememberMe", "false");

				      this.rememberMe = true;
				      this.rememberMeVisible = true;
                      //try to sign in
				      if (localStorage.getItem("longLivedToken") != null)
				      {
					      this.hideLogin = true;

					      this.pinnacleService.GetUserAccessKeyFromLongLivedToken(localStorage.getItem("longLivedToken")).subscribe(r => {
						      if (r.userAccessKey.length > 0) {
							      this.tempUserAccessKey = r.userAccessKey as string;
                                  this.tempRememberMeToken = r.longLivedToken as string;
                                  this.tempAPIV2AccessKey = r.apiV2AccessKey;

                                  ProdGenApi.setUserAccessKey(this.tempUserAccessKey);
                                  ProdGenApi.setAPIV2BearerToken(this.tempAPIV2AccessKey);
                                  this._sharedService.setV2BearerToken(this.tempAPIV2AccessKey);
                                  ProdGenApi.setUserIsExternal(r.isExternalUser);

                                  if (!this.redirectToAdmin()) {

                                      this.pinnacleService.GetTenantFromAccessKey(this.tempUserAccessKey).subscribe(tenantRes => {
                                          ProdGenApi.setSessionTenant(tenantRes);

                                          this.pinnacleService.getPoliciesForUser(r.email, r.tenantid).subscribe(policiesResult => {

                                              if (policiesResult.length > 0) {

                                                  //Check KS Integration
                                                  ProdGenApi.setAPIV2BearerToken(this.tempAPIV2AccessKey);
                                                  this._sharedService.setV2BearerToken(this.tempAPIV2AccessKey);


                                                  this.APIv2.getKSIntegrationConfigInfo().subscribe(ksci => {
                                                      this.KSConfigInfo = ksci.knowledgeSmartConfiguration;


                                                      //Check KS Integration
                                                      if (this.KSConfigInfo.useKnowledgeSmartIntegration == true) {
                                                          //Check if accepted in KS
                                                          this.APIv2.getTermsAndConditionsAcceptedKS(r.email).subscribe(ksres => {
                                                              if (ksres.acceptedTermsAndConditions == false) {
                                                                  //Have user accept terms
                                                                  this.policyList = policiesResult;
                                                                  this.policyTotalNum = policiesResult.length;
                                                                  this.loadPolicy();
                                                                  $('#policyBox').modal('show');
                                                              }
                                                              else {

                                                                  //user accepteded in KS, Mark it
                                                                  //Call API
                                                                  this.pinnacleService.markPolicyAcceptedForUser(this.email, this.selectedTenantId, ksres.policyId, ksres.acceptedDate).subscribe(r => {

                                                                      //Remove the Policy out the List
                                                                      let removeIndex: number = policiesResult.findIndex(x => x.policyID == ksres.policyId);
                                                                      this.policyList = new Array<Policy>();
                                                                      for (let i = 0; i < policiesResult.length; i++) {
                                                                          if (i != removeIndex) {
                                                                              this.policyList.push(policiesResult[i]);
                                                                          }
                                                                      }


                                                                      //show the Policy Box if there's any Policies left.
                                                                      if (this.policyList.length > 0) {


                                                                          //forEach()
                                                                          //this.policyList = policiesResult; //Taken care of above
                                                                          this.policyTotalNum = this.policyList.length;
                                                                          this.loadPolicy();
                                                                          $('#policyBox').modal('show');
                                                                      }
                                                                      else {
                                                                          //Login in user
                                                                          this.loginUser();

                                                                      }

                                                                  }, err => {
                                                                      this.errorAcceptingPolicy();
                                                                      return;
                                                                  });
                                                              }
                                                          });

                                                      }
                                                      else {
                                                          this.policyList = policiesResult;
                                                          this.policyTotalNum = policiesResult.length;
                                                          this.loadPolicy();
                                                          $('#policyBox').modal('show');
                                                      }
                                                  }, err => {
                                                      this.KSConfigInfo = new KnowledgeSmartConfiguration();
                                                      this.KSConfigInfo.useKnowledgeSmartIntegration = false;
                                                      this.KSConfigInfo.knowledgeSmartAPI_Key = "";
                                                      this.KSConfigInfo.assessmentSelfEnroll = false;

                                                      this.policyList = policiesResult;
                                                      this.policyTotalNum = policiesResult.length;
                                                      this.loadPolicy();
                                                      $('#policyBox').modal('show');
                                                  });
                                              }
                                              else {
                                                  this.loginUser();
                                              }
                                          }, err => {
                                              this.hideLogin = false;
                                          });

                                      }, err => {
                                          this.hideLogin = false;
                                      });
                                  }
                              }
                              else {
                                  this.hideLogin = false;
                              }

					      },
						      e => { this.hideLogin = false;  });
				      }
			      }
          }

      
          /****** Redirect to specific page if already logged in *******/
          if (localStorage.getItem("isAuthenicated") == "saml") {
              var userEmail = localStorage.getItem("userEmail");
              var tenantId = localStorage.getItem("lastUsedTenant");

              this.pinnacleService.GetSamlRequestURL(tenantId, userEmail).subscribe(url => {
                  //get langauge preference


                  var userEmail = localStorage.getItem("userEmail");


                  if (userEmail != "") {
                      this.pinnacleService.getEmailLanguagePreference(userEmail, this.selectedTenantId).subscribe(preference => {



                          if (preference != "") {
                              localStorage.setItem("ssoLanguageSetting", preference);
                          }

                          localStorage.setItem("isAuthenicated", "");
                          window.location.href = url;
                      });
                  }

                  

                

              });
          }
          else if (localStorage.getItem("isAuthenicated") == "openID") {
            var userEmail = localStorage.getItem("userEmail");
            var tenantId = localStorage.getItem("lastUsedTenant");

              this.pinnacleService.GetOpenIdRequestURL(tenantId, userEmail).subscribe(url => {
                  //get langage preference

                  this.pinnacleService.getEmailLanguagePreference(this.email, this.selectedTenantId).subscribe(preference => {
                      if (preference != "") {
                          localStorage.setItem("ssoLanguageSetting", preference);
                      }

                      localStorage.setItem("isAuthenicated", "");
                      window.location.href = url;
                  });
              });


		    }
          else if (localStorage.getItem("userEmail") != null) {
		      if (localStorage.getItem("userEmail") != "") {
			      this.email = localStorage.getItem("userEmail");
			      //check settings
			      this.onEmailLostFocus();
		      }
          }


        });
       
  }

    ngAfterViewInit() {
        this.signInButtonSettings();
        this.SetLogo();
    }

    routeAfterLogin() {
        this._sharedService.emitChange(this.email);

        if (environment.logRocketAppId && window.location.hostname !== 'localhost') {
            const user = ProdGenApi.getSessionUser();
            const session = {
                email: user.email,
                name: user.name,
            };
            LogRocket.identify(user.userId, session);
        }

        if (this.contentRedirectUrl) {
            // URL can be a link to classic or peak content:
            if (this.contentUrlService.isRedirectUrlToPeakContent(this.contentRedirectUrl)) {
                let contentRedirectId = null;
                const paramParts = this.contentRedirectUrl.split('content/');
                if (paramParts.length > 1) {
                    contentRedirectId = paramParts[1];
                    this.getContentTypeAndFormClassicRedirectUrl(contentRedirectId).subscribe(url => {
                        this.router.navigateByUrl(url);
                    })
                }
                else {
                    this.router.navigate(['/home']);
                }
            }
            else if (this.contentUrlService.isRedirectUrlToClassicContent(this.contentRedirectUrl)) {
                this.router.navigateByUrl(this.contentRedirectUrl);
            }
            else {
                this.router.navigate(['/home']);
            }
        }
        else {
            let v_URL = localStorage.getItem("LoginURL");

            // if Command Launcher utility is isntalled, let it know the userid of the current user

            if (v_URL == null || v_URL == "") {
                if (ProdGenApi.GetUserIsExternal() == true) {
                    this.router.navigate(['/external-home']);
                }
                else {
                    this.router.navigate(['/home']);
                }
            }
            else {
                v_URL = v_URL.replace(window.location.origin + "/#", "");
                this.router.navigateByUrl(v_URL);
                this.hideLogin = false;

                localStorage.setItem("LoginURL", "");
            }
        }
    }

    redirectToAdmin(): boolean {
        localStorage.setItem("tenantInformation", JSON.stringify(this.tempAPIV2AccessKey));
        if (this.redirectURL) {//if redirected here from admin browser to login 
            let url = window.location.protocol + "//" + window.location.host + "/administration/#" + this.redirectURL;
            window.location.href = url;
            return true;
        }
        return false;
    }

    tenantChanged(event) {
		this.pinnacleService.getPasswordPoliciesForUser(this.email, this.selectedTenantId).subscribe(r => {

			this.passwordPolicyEnforcedSetting = r.policyEnforced;
			this.complexPasswordSetting = r.requiresComplexPassword;
			this.passwordExpiresSetting = r.passwordExpires;
			this.passwordExpiresCount = r.numDaysPasExpires;
			this.passwordDifferentSetting = r.passwordMustBeDifferentFromPrev;
			this.passwordDiferentCount = r.numDiffPasCount;
			this.ssoSetting = r.disableWinAuth;
			this.forgotPasswordVisible = !r.disableForgotPassword;
			 
		});

	  this.pinnacleService.GetUserExcludedFromSSO(this.selectedTenantId, this.email).subscribe(r => {


      
      if (r == true) {
          this.isSSOUser = false;
          this.ssoSettingName = "";
      }
      else {
        this.checkSamlAuthentication(event);
		}

		this.signInButtonSettings();
	  });

        this.SetTheme();   
        this.SetLogo();
        
        this.pinnacleService.GetCurrentTenantSettings().subscribe(tenset => {
            let widgetSetting = tenset.find(v => v.name == "USE_WIDGETS");
            let bUseWidgets = false;
            if (widgetSetting) {
                bUseWidgets = widgetSetting.settingValue.toString().toLowerCase() == 'true';
            }
            else {
                bUseWidgets = false;
            }
            AppComponent.thisFromOutside.bUseWidgets = bUseWidgets;
            localStorage.setItem("usewidgets" + ProdGenApi.getCurrentTenantId(), bUseWidgets.toString());
        });
      
  }

    checkSamlAuthentication(id: string) {
        this.pinnacleService.IsSSOCompliantUser(id).subscribe(r => {

            if (r["SSO_CONFIG_SETTING"] != null) {
                this.ssoSettingName = r["SSO_CONFIG_SETTING"];

              if (this.ssoSettingName != "Pinnacle" && this.ssoSettingName != "" && this.ssoSettingName != "EWS (Exchange Web Services)") {
                  this.isSSOUser = true;
                  //$("input[type='password']").prop('disabled', true);
                }
                else {
                  this.isSSOUser = false;
                  
                  //$("input[type='password']").prop('disabled', false);
                }
            }

            else {
                this.isSSOUser = false;
                this.ssoSettingName = "";
              //$("input[type='password']").prop('disabled', false);
            }


			this.signInButtonSettings();
        });
    }

    onSignInClick(): void {

        
      ProdGenApi.setCurrentTenantId(this.selectedTenantId);
        ProdGenApi.setSessionTenant(this.tenants.find(x => x.tenantId == this.selectedTenantId));
        if (this.partnerOverrideSSO == true) {
			if (this.email != "") {
				
                this.pinnacleService.getHasPartnerSSO_Override(this.email).subscribe(res => {
                    if (res != null && res.link != "") {
                        window.location.href = res.link;
                        return;
                    }
                    else {
                        this.emailError = true;
                        this.partnerOverrideSSO = false;
                    }
                });

                this.pinnacleService.isNaviateUser(this.email).subscribe(res => {

                if (res == true) {

                    this.pinnacleService.getNaviateRequestURL(this.email).subscribe(url => {

                        if (url != null && url != "") {

                            window.location.href = url;
                            return;
                        }
                        else {
                            this.emailError = true;
                            this.partnerOverrideSSO = false;
                        }

                    });

                }
                else {
                    this.emailError = true;
                    this.partnerOverrideSSO = false;
                }

                });

			}
			else {
				this.emailError = true;
                this.partnerOverrideSSO = false;
                return;
			}
            
        }
    else if (this.ssoSettingName == "SAML 2.0") {
        this.pinnacleService.GetSamlRequestURL(this.selectedTenantId, this.email).subscribe(url => {

            localStorage.setItem("ssoLanguageSetting", this.selectedLanguage);

        window.location.href = url;//"https://eaglepoint.onelogin.com/trust/saml2/http-post/sso/855232%20?%20SAMLRequest=PHNhbWxwOkF1dGhuUmVxdWVzdCBJRD0iX2E3NjgxM2U0LTgwNmYtNDY0Zi04ZjdmLWQ2ZmMzN2IzYTFhOSIgVmVyc2lvbj0iMi4wIiBJc3N1ZUluc3RhbnQ9IjIwMTgtMTEtMjlUMTQ6MzE6NTdaIiBQcm90b2NvbEJpbmRpbmc9InVybjpvYXNpczpuYW1lczp0YzpTQU1MOjIuMDpiaW5kaW5nczpIVFRQLVBPU1QiIEFzc2VydGlvbkNvbnN1bWVyU2VydmljZVVSTD0iaHR0cDovL2xvY2FsaG9zdDo2MjExNC9Db25zdW1lLmFzcHgiIHhtbG5zOnNhbWxwPSJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6cHJvdG9jb2wiPjxzYW1sOklzc3VlciB4bWxuczpzYW1sPSJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6YXNzZXJ0aW9uIj50ZXN0LWFwcDwvc2FtbDpJc3N1ZXI%2bPHNhbWxwOk5hbWVJRFBvbGljeSBGb3JtYXQ9InVybjpvYXNpczpuYW1lczp0YzpTQU1MOjIuMDpuYW1laWQtZm9ybWF0OnVuc3BlY2lmaWVkIiBBbGxvd0NyZWF0ZT0idHJ1ZSIgLz48c2FtbHA6UmVxdWVzdGVkQXV0aG5Db250ZXh0IENvbXBhcmlzb249ImV4YWN0Ij48c2FtbDpBdXRobkNvbnRleHRDbGFzc1JlZiB4bWxuczpzYW1sPSJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6YXNzZXJ0aW9uIj51cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6YWM6Y2xhc3NlczpQYXNzd29yZFByb3RlY3RlZFRyYW5zcG9ydDwvc2FtbDpBdXRobkNvbnRleHRDbGFzc1JlZj48L3NhbWxwOlJlcXVlc3RlZEF1dGhuQ29udGV4dD48L3NhbWxwOkF1dGhuUmVxdWVzdD4%3d";
        
      });
    }
    else if (this.ssoSettingName == "OpenID") {

        localStorage.setItem("ssoLanguageSetting", this.selectedLanguage);

      this.pinnacleService.GetOpenIdRequestURL(this.selectedTenantId, this.email).subscribe(url => {
        window.location.href = url;//"https://eaglepoint.onelogin.com/trust/saml2/http-post/sso/855232%20?%20SAMLRequest=PHNhbWxwOkF1dGhuUmVxdWVzdCBJRD0iX2E3NjgxM2U0LTgwNmYtNDY0Zi04ZjdmLWQ2ZmMzN2IzYTFhOSIgVmVyc2lvbj0iMi4wIiBJc3N1ZUluc3RhbnQ9IjIwMTgtMTEtMjlUMTQ6MzE6NTdaIiBQcm90b2NvbEJpbmRpbmc9InVybjpvYXNpczpuYW1lczp0YzpTQU1MOjIuMDpiaW5kaW5nczpIVFRQLVBPU1QiIEFzc2VydGlvbkNvbnN1bWVyU2VydmljZVVSTD0iaHR0cDovL2xvY2FsaG9zdDo2MjExNC9Db25zdW1lLmFzcHgiIHhtbG5zOnNhbWxwPSJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6cHJvdG9jb2wiPjxzYW1sOklzc3VlciB4bWxuczpzYW1sPSJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6YXNzZXJ0aW9uIj50ZXN0LWFwcDwvc2FtbDpJc3N1ZXI%2bPHNhbWxwOk5hbWVJRFBvbGljeSBGb3JtYXQ9InVybjpvYXNpczpuYW1lczp0YzpTQU1MOjIuMDpuYW1laWQtZm9ybWF0OnVuc3BlY2lmaWVkIiBBbGxvd0NyZWF0ZT0idHJ1ZSIgLz48c2FtbHA6UmVxdWVzdGVkQXV0aG5Db250ZXh0IENvbXBhcmlzb249ImV4YWN0Ij48c2FtbDpBdXRobkNvbnRleHRDbGFzc1JlZiB4bWxuczpzYW1sPSJ1cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6YXNzZXJ0aW9uIj51cm46b2FzaXM6bmFtZXM6dGM6U0FNTDoyLjA6YWM6Y2xhc3NlczpQYXNzd29yZFByb3RlY3RlZFRyYW5zcG9ydDwvc2FtbDpBdXRobkNvbnRleHRDbGFzc1JlZj48L3NhbWxwOlJlcXVlc3RlZEF1dGhuQ29udGV4dD48L3NhbWxwOkF1dGhuUmVxdWVzdD4%3d";

      });
    }
        

    else {
      if (this.email != "" && this.password != "" && this.selectedTenantId == "") {
        //load tenant
        this.pinnacleService.GetUserOrgInfo(this.email).subscribe(r => {
          this.emailError = false;
          this.tenants = r.tenantList;
          if (this.tenants.length > 0) {
            this.selectedTenantId = this.tenants[0].tenantId as string;

            this.emailAuth = true;
            let savedTenant = localStorage.getItem("DefaultTenant");
            if (!(this.tenants.length == 1 || savedTenant == "")) {
              for (var i = 0; i < this.tenants.length; i++) {
                if (savedTenant == this.tenants[i].tenantId as string) {
                  this.selectedTenantId = this.tenants[i].tenantId as string;
                }
              }
              }

            this.forgotPasswordVisible = true;
            this.rememberMeVisible = true;

            this.onSignInClick();
          }
        }, e => {
          this.emailError = true;
          this.forgotPasswordVisible = false;
          this.rememberMeVisible = false;
        })


        return;
      }

      //ProdGenApi.setCurrentTenantId(this.selectedTenantId);

        this.pinnacleService.GetUserAccessKey(this.email, this.password, this.selectedTenantId, this.selectedLanguage, this.rememberMe).subscribe(r => {
            
            if (r.userAccessKey.length > 0) {

                localStorage.setItem("DefaultTenant", r.tenantid);
                sessionStorage.setItem("currentTenant", r.tenantid);
                localStorage.setItem("lastUsedTenant", r.tenantid);
				/*
				localStorage.removeItem(this.selectedTenantId + "_partner_news_image");
				*/

                this.tempUserAccessKey = r.userAccessKey as string;
                this.tempAPIV2AccessKey = r.apiV2AccessKey;
                this.tempRememberMeToken = r.longLivedToken as string;

                ProdGenApi.setUserIsExternal(r.isExternalUser);

                if (!this.redirectToAdmin()) {

                    this.pinnacleService.getPoliciesForUser(r.email, r.tenantid).subscribe(policiesResult => {

                        if (policiesResult.length > 0) {


                            //Check KS Integration
                            ProdGenApi.setAPIV2BearerToken(this.tempAPIV2AccessKey);
                            this._sharedService.setV2BearerToken(this.tempAPIV2AccessKey);

                            console.log("A");
                            console.log(this.tempAPIV2AccessKey);

                            this.APIv2.getKSIntegrationConfigInfo().subscribe(ksci => {

                                //console.log("A1");

                                this.KSConfigInfo = ksci.knowledgeSmartConfiguration;

                                //Check KS Integration
                                if (this.KSConfigInfo.useKnowledgeSmartIntegration == true) {
                                    //Check if accepted in KS
                                    this.APIv2.getTermsAndConditionsAcceptedKS(r.email).subscribe(ksres => {
                                        if (ksres.acceptedTermsAndConditions == false) {
                                            //Have user accept terms
                                            this.policyList = policiesResult;
                                            this.policyTotalNum = policiesResult.length;
                                            this.loadPolicy();
                                            $('#policyBox').modal('show');
                                        }
                                        else {

                                            //user accepteded in KS, Mark it
                                            //Call API
                                            this.pinnacleService.markPolicyAcceptedForUser(this.email, this.selectedTenantId, ksres.policyId, ksres.acceptedDate).subscribe(r => {

                                                //Remove the Policy out the List
                                                let removeIndex: number = policiesResult.findIndex(x => x.policyID == ksres.policyId);
                                                this.policyList = new Array<Policy>();
                                                for (let i = 0; i < policiesResult.length; i++) {
                                                    if (i != removeIndex) {
                                                        this.policyList.push(policiesResult[i]);
                                                    }
                                                }

                                                //show the Policy Box if there's any Policies left.
                                                if (this.policyList.length > 0) {


                                                    //forEach()
                                                    //this.policyList = policiesResult; //Taken care of above
                                                    this.policyTotalNum = this.policyList.length;
                                                    this.loadPolicy();
                                                    $('#policyBox').modal('show');
                                                }
                                                else {
                                                    //Login in user
                                                    this.loginUser();

                                                }

                                            }, err => {
                                                this.errorAcceptingPolicy();
                                                return;
                                            });
                                        }
                                    });

                                }
                                else {
                                    this.policyList = policiesResult;
                                    this.policyTotalNum = policiesResult.length;
                                    this.loadPolicy();
                                    $('#policyBox').modal('show');
                                }
                            },
                                err => {
                                    this.KSConfigInfo = new KnowledgeSmartConfiguration();
                                    this.KSConfigInfo.useKnowledgeSmartIntegration = false;
                                    this.KSConfigInfo.knowledgeSmartAPI_Key = "";
                                    this.KSConfigInfo.assessmentSelfEnroll = false;

                                    this.policyList = policiesResult;
                                    this.policyTotalNum = policiesResult.length;
                                    this.loadPolicy();
                                    $('#policyBox').modal('show');
                                });
                        }
                        else {
                            this.loginUser();
                            // TEMP launch in tandem
                            this.APIv2.checkLaunchPeakPortal(this.contentRedirectUrl);
                        }
                    });
                }
            }
        },
            e => { this.authError = true; this.hideLogin = false; }
		);

		
    }
    }



	loginUser() {
		this.hideLogin = true;
		this.authError = false;
		// successful login, store the info and move on

		if (this.tempUserAccessKey != null && this.tempUserAccessKey != "") {
			//save email
			localStorage.setItem("userEmail", this.email);


			ProdGenApi.setUserAccessKey(this.tempUserAccessKey as string);
            ProdGenApi.setAPIV2BearerToken(this.tempAPIV2AccessKey);
            this._sharedService.setV2BearerToken(this.tempAPIV2AccessKey);

			if (this.rememberMe) {
				localStorage.setItem("rememberMe", "true");
				localStorage.setItem("longLivedToken", this.tempRememberMeToken);

			}
			else {
				localStorage.setItem("rememberMe", "false");
				localStorage.setItem("longLivedToken", "");
			}

            sessionStorage.removeItem('sessionLoggedOut');//If the sessionLoggedOut sessionStorage is still out there from prior time out, clear it on successful sign into Pinnacle browser.
            this.loadTransitionFile();
            this.processKnowledgeSmartAssessment();
		}
	}


  loadTransitionFile() {
      //debug - run local
      if ((window.location.href.includes("localhost") || window.location.href.includes("https://prodgenbrowser") || window.location.href.includes("https://portalbeta")) && this.selectedLanguage=="en") {
      this.tranService.loadTranslationFileLocal().subscribe(dataFile => {

        this.tranService.loadTranslationFileDataFromVariable(dataFile);
        this.onTranslationLoaded(false);

      },
        err => {
          console.log(err);
        });
    }
    else {
      this.pinnacleService.getTranslatedFile().subscribe(dataFile => {

        this.tranService.loadTranslationFileDataFromVariable(dataFile);
        this.onTranslationLoaded(false);

      },
        fileError => {
          this.tranService.loadTranslationFileLocal();
          this.onTranslationLoaded(false);
        });
    }
  }

	onTranslationLoaded(emitChange: boolean)
	{
		this.loadTranslatedText();
		// when we login, we will store with the session some information about the user, org and tenant
		this.pinnacleService.getCurrentUser().subscribe(u => 
		{			
			ProdGenApi.setSessionUser(u);
			if (emitChange) {
				this._sharedService.emitChange(u.email);
            }

			//save language preference
			this.pinnacleService.saveCurrentUserSetting("LanguagePreference", this.selectedLanguage, SettingType_T.string).subscribe();

        });
        
        this.pinnacleService.GetCurrentTenant().subscribe(t => {  ProdGenApi.setSessionTenant(t); });
		this.pinnacleService.GetCurrentOrganization().subscribe(o => { ProdGenApi.setSessionOrganization(o); });



		//Get password settings
		this.pinnacleService.GetCurrentTenantSettings().subscribe(res => {


			res.forEach((setting) => {
				if (setting.name == "PWD_ENFORCE_POLICY") {
					this.passwordPolicyEnforcedSetting = Boolean(setting.settingValue) ;
				}
				else if (setting.name == "PWD_USE_COMPLEX_PASSWORDS") {
          this.complexPasswordSetting = Boolean(setting.settingValue);
				}
				else if (setting.name == "PWD_PASSWORD_EXPIRES") {
          this.passwordExpiresSetting = Boolean(setting.settingValue);
				}
				else if (setting.name == "PWD_EXPIRATION_DURATION") {
					this.passwordExpiresCount = setting.settingValue as number;
				}
				else if (setting.name == "PWD_MUST_BE_UNIQUE") {
          this.passwordDifferentSetting = Boolean(setting.settingValue);
				}
				else if (setting.name == "PWD_UNIQUE_HISTORY_COUNT") {
					this.passwordDiferentCount = setting.settingValue as number;
				}
				else if (setting.name == "PWD_DISABLE_WINDOWS_AUTH") {
          this.ssoSetting = Boolean(setting.settingValue);
				}
        

            });

            let widgetSetting = res.find(v => v.name == "USE_WIDGETS");
            let bUseWidgets = false;
            if (widgetSetting) {
                bUseWidgets = widgetSetting.settingValue.toString().toLowerCase() == 'true';
            }
            else {
                bUseWidgets = false;
            }
            AppComponent.thisFromOutside.bUseWidgets = bUseWidgets;
            localStorage.setItem("usewidgets" + ProdGenApi.getCurrentTenantId(), bUseWidgets.toString());
            this.WidgetLibSvc.setIsUsingWidget(bUseWidgets);


          if (this.ssoSetting == false) {
				this.pinnacleService.isCurrentPasswordExpired().subscribe(res2 => {
					if (res2 == true) {
						//this.storedKey = r as string;
						//ProdGenApi.setUserAccessKey("");
						this.hideLogin = false;
						//ProdGenApi.setUserAccessKey("");

						$('#changeUserPassword').modal('show');

					}
					else {
						// why refresh? because it relods app.component to load all org and user settings correctly.
						//window.location.reload();
						this.routeAfterLogin();
					}
				});
			}
			else {
				// why refresh? because it relods app.component to load all org and user settings correctly.
				//window.location.reload();
				this.routeAfterLogin();
			}


		});
	}

    SetLogo(){
        // determine if the logo should be light or dark based on the header background color
        var bg = window.getComputedStyle(this.elementref.nativeElement);
        var bgColor = bg.getPropertyValue("--panel-title-background-color");
        var backgroundColor = hexToRgb(bgColor);

        const {r, g, b} = backgroundColor;
        const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
        if (window.location.href.toLocaleLowerCase().includes("productivitynow.imaginit.com")) {
            this.signInLogo = "../../assets/images/signin_imaginit.png";
        }
        else if (window.location.href.toLocaleLowerCase().includes("productivitynow.ascented.com")) {
            this.signInLogo = "../../assets/images/signin_ascent.png";
        }
        else if (window.location.href.toLocaleLowerCase().includes("productivitynow.rand3d.com")) {
            this.signInLogo = "../../assets/images/signin_rand.png";
        }
        else if (hsp < 127.5) {
            this.signInLogo = this.psSignInLogoLight;
        } else {
            this.signInLogo = this.psSignInLogoDark;
        }
    }

    SetTheme() {
        let appliedtheme = new Theme();
        try {
            appliedtheme = JSON.parse(localStorage.getItem("AppliedTheme" + this.selectedTenantId));

            Object.keys(this.previewtheme.properties).forEach(property => {
                if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                    this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue + "px";

                }
                else if (property.indexOf("font-face") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }
                else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }

                if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                    document.documentElement.style.setProperty(
                        property,
                        this.previewtheme.properties[property]
                    );
                }
            });

            Object.keys(currenttheme.properties).forEach(property => {
                if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                    currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue + "px";

                }
                else if (property.indexOf("font-face") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }
                else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1 && property.indexOf("label") == -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }

                if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1 && property.indexOf("label") == -1) {
                    document.documentElement.style.setProperty(
                        property,
                        currenttheme.properties[property]
                    );
                }
            });



            localStorage.setItem(this.selectedTenantId + "_DocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("document_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_MyDocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("my_document_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_VidsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("video_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_CourseImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("course_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_LPImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("learning_path_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_WFImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("workflow_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_ProcessImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("process_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_QuizImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("quiz_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_defWorkgroupImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("workgroup_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_EnrollmentImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("enrollment_image")].itemValue);

            cssVars({
                rootElement: document,
                variables: {
                    "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                    "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                    "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                    "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                    "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                    "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                    "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                    "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                    "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                    "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                    "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                    "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                    "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                    "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                    "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                    "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                    "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                    "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                    "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                    "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                    "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                    "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                    "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                    "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                    "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                    "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                    "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                    "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                    "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                    "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                    "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                    "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                    "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                    "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                    "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                    "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                    "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                    "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                    "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                    "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                    "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                    "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                    "--font-face": currenttheme.properties["--font-face"],
                    "--page-background-color": currenttheme.properties["--page-background-color"],
                    "--page-text-color": currenttheme.properties["--page-text-color"],
                    "--page-separator-color": currenttheme.properties["--page-separator-color"],
                    "--page-separator-width": currenttheme.properties["--page-separator-width"],
                    "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                    "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                    "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                    "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                    "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                    "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                    "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                    "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                    "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                    "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                    "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                    "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                    "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                    "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                    "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                    "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                    "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                    "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                    "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                    "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                    "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                    "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                    "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                    "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                    "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                    "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                    "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                    "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                    "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                    "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                    "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                    "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                    "--panel-border-color": currenttheme.properties["--panel-border-color"],
                    "--panel-border-width": currenttheme.properties["--panel-border-width"],
                    "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                    "--button-background": currenttheme.properties["--button-background"],
                    "--button-foreground": currenttheme.properties["--button-foreground"]
                }
            });
        } catch (e) {

        }

        this.pinnacleService.GetCurrentTenantThemeSettingAtLogin(this.selectedTenantId, this.email).subscribe(res => {
            appliedtheme = res;

            localStorage.setItem("AppliedTheme" + this.selectedTenantId, JSON.stringify(appliedtheme));

            Object.keys(this.previewtheme.properties).forEach(property => {
                if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                    this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue + "px";

                }
                else if (property.indexOf("font-face") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }
                else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    this.previewtheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--preview-" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }

                if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1) {
                    document.documentElement.style.setProperty(
                        property,
                        this.previewtheme.properties[property]
                    );
                }
            });

            Object.keys(currenttheme.properties).forEach(property => {
                if (property.indexOf("width") != -1 || property.indexOf("radius") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue + "px";
                    currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue + "px";

                }
                else if (property.indexOf("font-face") != -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }
                else if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1 && property.indexOf("label") == -1) {
                    //this.previewtheme.properties[property] = this.selectedTheme.themeproperties[this.selectedTheme.themeproperties.indexOf(x => "--preview-" + x.itemName.replace(/_/g, "-") == property)].itemValue;
                    currenttheme.properties[property] = appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                        function (e) {
                            return "--" + e.itemName.replace(/_/g, "-")
                        }).indexOf(property)].itemValue;

                }

                if (property.indexOf("themeid") == -1 && property.indexOf("tenantid") == -1 && property.indexOf("is_public") == -1 && property.indexOf("name") == -1 && property.indexOf("label") == -1) {
                    document.documentElement.style.setProperty(
                        property,
                        currenttheme.properties[property]
                    );
                }
            });



            localStorage.setItem(this.selectedTenantId + "_DocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                                                                        function (e) {
                                                                            return e.itemName
                                                                        }).indexOf("document_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_MyDocsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("my_document_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_VidsImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("video_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_CourseImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("course_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_LPImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("learning_path_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_WFImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("workflow_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_ProcessImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("process_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_QuizImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("quiz_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_defWorkgroupImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("workgroup_image")].itemValue);
            localStorage.setItem(this.selectedTenantId + "_EnrollmentImage", appliedtheme.themeproperties[appliedtheme.themeproperties.map(
                function (e) {
                    return e.itemName
                }).indexOf("enrollment_image")].itemValue);

            cssVars({
                rootElement: document,
                variables: {
                    "--preview-font-face": this.previewtheme.properties["--preview-font-face"],
                    "--preview-page-background-color": this.previewtheme.properties["--preview-page-background-color"],
                    "--preview-page-text-color": this.previewtheme.properties["--preview-page-text-color"],
                    "--preview-page-separator-color": this.previewtheme.properties["--preview-page-separator-color"],
                    "--preview-page-separator-width": this.previewtheme.properties["--preview-page-separator-width"],
                    "--preview-navbar-background-color": this.previewtheme.properties["--preview-navbar-background-color"],
                    "--preview-navbar-foreground-color": this.previewtheme.properties["--preview-navbar-foreground-color"],
                    "--preview-hsl-background-color": this.previewtheme.properties["--preview-hsl-background-color"],
                    "--preview-hsl-foreground-color": this.previewtheme.properties["--preview-hsl-foreground-color"],
                    "--preview-hsl-border-color": this.previewtheme.properties["--preview-hsl-border-color"],
                    "--preview-hsl-border-width": this.previewtheme.properties["--preview-hsl-border-width"],
                    "--preview-hsr-background-color": this.previewtheme.properties["--preview-hsr-background-color"],
                    "--preview-hsr-foreground-color": this.previewtheme.properties["--preview-hsr-foreground-color"],
                    "--preview-hsr-border-color": this.previewtheme.properties["--preview-hsr-border-color"],
                    "--preview-hsr-border-width": this.previewtheme.properties["--preview-hsr-border-width"],
                    "--preview-large-card-background-color1": this.previewtheme.properties["--preview-large-card-background-color1"],
                    "--preview-large-card-foreground-color1": this.previewtheme.properties["--preview-large-card-foreground-color1"],
                    "--preview-large-card-background-color2": this.previewtheme.properties["--preview-large-card-background-color2"],
                    "--preview-large-card-foreground-color2": this.previewtheme.properties["--preview-large-card-foreground-color2"],
                    "--preview-large-card-background-color3": this.previewtheme.properties["--preview-large-card-background-color3"],
                    "--preview-large-card-foreground-color3": this.previewtheme.properties["--preview-large-card-foreground-color3"],
                    "--preview-large-card-border-color": this.previewtheme.properties["--preview-large-card-border-color"],
                    "--preview-large-card-border-width": this.previewtheme.properties["--preview-large-card-border-width"],
                    "--preview-large-card-border-radius": this.previewtheme.properties["--preview-large-card-border-radius"],
                    "--preview-small-card-background-color1": this.previewtheme.properties["--preview-small-card-background-color1"],
                    "--preview-small-card-foreground-color1": this.previewtheme.properties["--preview-small-card-foreground-color1"],
                    "--preview-small-card-background-color2": this.previewtheme.properties["--preview-small-card-background-color2"],
                    "--preview-small-card-foreground-color2": this.previewtheme.properties["--preview-small-card-foreground-color2"],
                    "--preview-small-card-background-color3": this.previewtheme.properties["--preview-small-card-background-color3"],
                    "--preview-small-card-foreground-color3": this.previewtheme.properties["--preview-small-card-foreground-color3"],
                    "--preview-small-card-border-color": this.previewtheme.properties["--preview-small-card-border-color"],
                    "--preview-small-card-border-width": this.previewtheme.properties["--preview-small-card-border-width"],
                    "--preview-small-card-border-radius": this.previewtheme.properties["--preview-small-card-border-radius"],
                    "--preview-panel-title-background-color": this.previewtheme.properties["--preview-panel-title-background-color"],
                    "--preview-panel-title-foreground-color": this.previewtheme.properties["--preview-panel-title-foreground-color"],
                    "--preview-panel-body-background-color": this.previewtheme.properties["--preview-panel-body-background-color"],
                    "--preview-panel-body-foreground-color": this.previewtheme.properties["--preview-panel-body-foreground-color"],
                    "--preview-panel-border-color": this.previewtheme.properties["--preview-panel-border-color"],
                    "--preview-panel-border-width": this.previewtheme.properties["--preview-panel-border-width"],
                    "--preview-panel-border-radius": this.previewtheme.properties["--preview-panel-border-radius"],
                    "--preview-button-background": this.previewtheme.properties["--preview-button-background"],
                    "--preview-button-foreground": this.previewtheme.properties["--preview-button-foreground"],

                    "--font-face": currenttheme.properties["--font-face"],
                    "--page-background-color": currenttheme.properties["--page-background-color"],
                    "--page-text-color": currenttheme.properties["--page-text-color"],
                    "--page-separator-color": currenttheme.properties["--page-separator-color"],
                    "--page-separator-width": currenttheme.properties["--page-separator-width"],
                    "--navbar-background-color": currenttheme.properties["--navbar-background-color"],
                    "--navbar-foreground-color": currenttheme.properties["--navbar-foreground-color"],
                    "--hsl-background-color": currenttheme.properties["--hsl-background-color"],
                    "--hsl-foreground-color": currenttheme.properties["--hsl-foreground-color"],
                    "--hsl-border-color": currenttheme.properties["--hsl-border-color"],
                    "--hsl-border-width": currenttheme.properties["--hsl-border-width"],
                    "--hsr-background-color": currenttheme.properties["--hsr-background-color"],
                    "--hsr-foreground-color": currenttheme.properties["--hsr-foreground-color"],
                    "--hsr-border-color": currenttheme.properties["--hsr-border-color"],
                    "--hsr-border-width": currenttheme.properties["--hsr-border-width"],
                    "--large-card-background-color1": currenttheme.properties["--large-card-background-color1"],
                    "--large-card-foreground-color1": currenttheme.properties["--large-card-foreground-color1"],
                    "--large-card-background-color2": currenttheme.properties["--large-card-background-color2"],
                    "--large-card-foreground-color2": currenttheme.properties["--large-card-foreground-color2"],
                    "--large-card-background-color3": currenttheme.properties["--large-card-background-color3"],
                    "--large-card-foreground-color3": currenttheme.properties["--large-card-foreground-color3"],
                    "--large-card-border-color": currenttheme.properties["--large-card-border-color"],
                    "--large-card-border-width": currenttheme.properties["--large-card-border-width"],
                    "--large-card-border-radius": currenttheme.properties["--large-card-border-radius"],
                    "--small-card-background-color1": currenttheme.properties["--small-card-background-color1"],
                    "--small-card-foreground-color1": currenttheme.properties["--small-card-foreground-color1"],
                    "--small-card-background-color2": currenttheme.properties["--small-card-background-color2"],
                    "--small-card-foreground-color2": currenttheme.properties["--small-card-foreground-color2"],
                    "--small-card-background-color3": currenttheme.properties["--small-card-background-color3"],
                    "--small-card-foreground-color3": currenttheme.properties["--small-card-foreground-color3"],
                    "--small-card-border-color": currenttheme.properties["--small-card-border-color"],
                    "--small-card-border-width": currenttheme.properties["--small-card-border-width"],
                    "--small-card-border-radius": currenttheme.properties["--small-card-border-radius"],
                    "--panel-title-background-color": currenttheme.properties["--panel-title-background-color"],
                    "--panel-title-foreground-color": currenttheme.properties["--panel-title-foreground-color"],
                    "--panel-body-background-color": currenttheme.properties["--panel-body-background-color"],
                    "--panel-body-foreground-color": currenttheme.properties["--panel-body-foreground-color"],
                    "--panel-border-color": currenttheme.properties["--panel-border-color"],
                    "--panel-border-width": currenttheme.properties["--panel-border-width"],
                    "--panel-border-radius": currenttheme.properties["--panel-border-radius"],
                    "--button-background": currenttheme.properties["--button-background"],
                    "--button-foreground": currenttheme.properties["--button-foreground"]
                }
            });
        });
    }

    onEmailLostFocus(): void
    {
        this.emailValidated = false;
        this.pinnacleService.GetUserOrgInfo(this.email).subscribe(r => {
            this.emailError = false;
            this.tenants = r.tenantList;
            if (this.tenants.length > 0)
            {
                let tenantIndex = 0;    
                
                this.selectedTenantId = this.tenants[0].tenantId as string;

				        this.emailAuth = true;
				        let savedTenant = localStorage.getItem("DefaultTenant");
				        if (!(this.tenants.length == 1 || savedTenant == "")) {
					          for (var i = 0; i < this.tenants.length; i++) {
						            if (savedTenant == this.tenants[i].tenantId as string) {
							            this.selectedTenantId = this.tenants[i].tenantId as string;
                                        tenantIndex = i;
						            }
							} 
 
                           
                }
                this.pinnacleService.getPasswordPoliciesForUser(this.email, this.selectedTenantId).subscribe(r => {
                    this.emailValidated = true;
                    this.passwordPolicyEnforcedSetting = r.policyEnforced;
                    this.complexPasswordSetting = r.requiresComplexPassword;
                    this.passwordExpiresSetting = r.passwordExpires;
                    this.passwordExpiresCount = r.numDaysPasExpires;
                    this.passwordDifferentSetting = r.passwordMustBeDifferentFromPrev;
                    this.passwordDiferentCount = r.numDiffPasCount;
                    this.ssoSetting = r.disableWinAuth;
                    this.forgotPasswordVisible = !r.disableForgotPassword;

                });

                this.SetTheme();
                this.SetLogo();     

                this.partnerOverrideSSO = false;

                

                
                if (this.partnerOverrideSSO == false) {
                    
                    this.pinnacleService.GetUserExcludedFromSSO(this.selectedTenantId, this.email).subscribe(r2 => {

                        if (r2 == true) {
                            this.isSSOUser = false;
                        }
                        else {

                            if (r.partnerId == "37f80070-91d5-4f70-863e-6d3e9e13afb1" && this.tenants[tenantIndex].isPinnacleLite == true) {
								
                                this.pinnacleService.getHasPartnerSSO_Override(this.email).toPromise().then((res) => {
                                    if (res != null) {
                                        this.isSSOUser = true;
                                        this.partnerOverrideSSO = true;
                                    }
                                    else {
                                        this._sharedService.setSelectedTenantId(this.selectedTenantId);
                                    }
                                });
								

								this.pinnacleService.isNaviateUser(this.email).subscribe(res => {

									if (res == true) {

										this.pinnacleService.getNaviateRequestURL(this.email).subscribe(url => {

											if (url != null && url != "") {

												this.isSSOUser = true;
												this.partnerOverrideSSO = true;
											}
											else {
												this._sharedService.setSelectedTenantId(this.selectedTenantId);
											}

										});

									}
									else {
										this._sharedService.setSelectedTenantId(this.selectedTenantId);
									}

								});

                                //let v_Tenant = r.tenantList.find(x => x.tenantId == this.selectedTenantId);


                            }
                            else {
                                this._sharedService.setSelectedTenantId(this.selectedTenantId);
                            }

                            
                        }
                    });
                }


                



                localStorage.setItem("lastUsedTenant", this.selectedTenantId);

				        this.forgotPasswordVisible = true;
				        this.rememberMeVisible = true;
			}

            this.pinnacleService.getEmailLanguagePreference(this.email, this.selectedTenantId).subscribe(preference => {
				        if (preference == "") {
					        this.selectedLanguage = "en";
				        }
				        else {
                            this.selectedLanguage = preference;
                            LoginComponent.staticSelectedLanguage = preference;
                }
                localStorage.setItem("selectedLanguage", this.selectedLanguage);
                localStorage.setItem("currentLanguageCode", this.selectedLanguage);
                if ((window.location.href.startsWith("https://localhost") || window.location.href.startsWith("https://prodgenbrowser") || window.location.href.startsWith("https://portalbeta")) && this.selectedLanguage=="en") {
                    this.tranService.loadTranslationFileLocal().subscribe(dataFile => {

                      this.tranService.loadTranslationFileDataFromVariable(dataFile);
                      //this.onTranslationLoaded(false);

                    },
                      err => {
                        console.log(err);
                      });
                }
                else {
                    this.pinnacleService.getTranslatedFileFromLanguageCode(this.selectedLanguage).subscribe(dataFile => {

                        this.tranService.loadTranslationFileDataFromVariable(dataFile);
                        this.loadTranslatedText();

                    },
                      fileError => {
                        this.tranService.loadTranslationFileLocal();
                        //this.onTranslationLoaded(false);
                      });
                }
			      }, err => {
                      this.selectedLanguage = "en";
                      LoginComponent.staticSelectedLanguage = "en";
            });
        }, e => {
			      this.emailError = true;
			      this.forgotPasswordVisible = false;
			      this.rememberMeVisible = false;
        })
    }

	forgotPasswordClick(): void
	{
		if (this.emailAuth == true && this.emailError == false) {
			this.pinnacleService.sendForgotPassword(this.email).subscribe(r => {
				this.messageBoxTitle = "An email has been sent";
				this.messageBoxBody = "You should receive an email to reset your password shortly.";
				this.messageBoxActionText = "";
				this.messageBoxNegativeText = "";
				this.messageBoxCancelText = "OK";

								
				//$('#changeUserPassword').modal('show');
				$('#messageBox').modal('show');
			});
		}
	}


	onPasswordChanged(event: any) {

		$("#changeUserPassword").modal("hide");
        
		if (event.passwordChanged) {
			this.hideLogin = true;
			//ProdGenApi.setUserAccessKey(this.storedKey);
			//window.location.reload();
			this.routeAfterLogin();
		}
		else {

		}
	}

	loadPolicy() {
		//var policyItem = this.policyList[this.policyCurrentNum - 1];
		//this.policyTitle = policyItem.title;

		//this.policyText = policyItem.text;
        this.policyChecked = false;
        /*
		if (this.policyCurrentNum == this.policyTotalNum) {
			this.PolicyAcceptButtonText = "Accept";
		}
		else {
			this.PolicyAcceptButtonText = "Next";
		}
        */
	}

	acceptPolicy() {
        if (this.policyChecked) {
            /*
			var policyItem = this.policyList[this.policyCurrentNum - 1];

			this.pinnacleService.acceptPolicyForUser(this.email, this.selectedTenantId, policyItem.policyID).subscribe(r => {
				if (r == true) {
					if (this.policyCurrentNum == this.policyTotalNum) {
						//hide dialog
						$('#policyBox').modal('hide');
						//this.onSignInClick();
						this.loginUser();
					}
					else {
						this.policyCurrentNum++;
						this.loadPolicy();
					}
				}
				else {
					this.errorAcceptingPolicy();
				}
			}, err => {
				this.errorAcceptingPolicy();
			});*/
            var i = 1;
            this.policyList.forEach(v_Policy => {
                this.pinnacleService.acceptPolicyForUser(this.email, this.selectedTenantId, v_Policy.policyID).subscribe(r => {

                }, err => {
                    this.errorAcceptingPolicy();
                    return;
                });
                
            });

            $('#policyBox').modal('hide');
            this.loginUser();
            this.APIv2.checkLaunchPeakPortal(this.contentRedirectUrl);
		}
	}

	errorAcceptingPolicy() {
		this.messageBoxTitle = "An error occurred accepting this policy";
		this.messageBoxBody = "The service was not able to register your acceptance of this policy.";
		this.messageBoxActionText = "";
		this.messageBoxNegativeText = "";
		this.messageBoxCancelText = "OK";

		$('#messageBox').modal('show');
	}

	cancelPolicy() {
		$('#policyBox').modal('hide');
		this.tempUserAccessKey = "";
		this.tempRememberMeToken = "";
	}

	getPolicyDesc(): SafeHtml {
		let v_Text = "";
		if (this.policyList.length > 0) {
			v_Text = this.policyList[this.policyCurrentNum - 1].text;	
		}
		
		return this._sanitizer.bypassSecurityTrustHtml(v_Text as string);
	}

	getAcceptButtonClass(): string {
		if (this.policyChecked) {
			return "btn themed-button";
		}
		else {
			return "btn disabled-button";
		}
  }

  onLanguageChanged(event) {
    const language = event.target.value;
    this.selectedLanguage = language;
    localStorage.setItem("selectedLanguage", language);
    localStorage.setItem("currentLanguageCode", language);
      if ((window.location.href.startsWith("http://localhost") || window.location.href.startsWith("https://prodgenbrowser") || window.location.href.startsWith("https://portalbeta")) && language=="en") {
      this.tranService.loadTranslationFileLocal().subscribe(dataFile => {

        this.tranService.loadTranslationFileDataFromVariable(dataFile);
          this.loadTranslatedText();
      },
        err => {
          console.log(err);
        });
    }
    else {
      this.pinnacleService.getTranslatedFileFromLanguageCode(language).subscribe(dataFile => {

        this.tranService.loadTranslationFileDataFromVariable(dataFile);
        this.loadTranslatedText();
      },
        fileError => {
          this.tranService.loadTranslationFileLocal();
        });
    }
    this.pinnacleService.invlidateCacheAll();

  }

  onSelectionTranslationLoaded() {
    //refresh page
    window.location.reload();
    }


    offlineViewerClick() {
    }

  loadTranslatedText() {
	  this.signInText = this.tranService.getTranslationFileData("HOME.SignIn");
	  this.rememberMeText = this.tranService.getTranslationFileData("HOME.RememberMe");
	  this.forgotPasswordText = this.tranService.getTranslationFileData("HOME.ForgotPassword");
	}

	signInButtonSettings() {
	 /*
		if (this.isSSOUser == true) {
			this.signInText = "Next";
			//if (this.email == '') {
				
   //           $('#signInBtn').attr("disabled", "disabled");;
			//}
			//else {
				
   //           $('#signInBtn').removeAttr("disabled");
			//}
		}
		else {
			this.signInText = "Sign In";
			//if (this.email == '' || this.password == '') {
				
   //           $('#signInBtn').attr("disabled", "disabled");;

			//}
			//else {
				
   //           $('#signInBtn').removeAttr("disabled");
			//}
		}
		*/
  }

  onPWchange() {
    this.signInButtonSettings();
    }

    //** A KS assessment may have been passed in the query params so we check it's valid here and open it if the tenant uses classic or we leave it for Peak to open.*/
    processKnowledgeSmartAssessment(): void {
        const ksAssessmentToTake = localStorage.getItem('[peak]ksAssessmentToTake');
        if (!ksAssessmentToTake) {
            localStorage.removeItem('[peak]ksAssessmentToTake');
            return;
        }

        const ksAssessmentData: KSInviteToAssessment = JSON.parse(ksAssessmentToTake).value;
        if (!ksAssessmentData || !this.isGuid(ksAssessmentData.psUserId, false) ||
            ksAssessmentData.ksInviteId <= 0 || ksAssessmentData.psUserId.toUpperCase() !== this.tempAPIV2AccessKey.userId.toUpperCase()) {
            localStorage.removeItem('[peak]ksAssessmentToTake');
            return;
        }

        forkJoin([
            this.pinnacleService.GetCurrentTenantSettings(),
            this.APIv2.getKSIntegrationConfigInfo(),
        ]).subscribe({
            next: ([settings, ksci]: [Setting[], GetKnowledgeSmartResponse]) => {
                if (!settings || settings.length === 0 || !ksci || !ksci.knowledgeSmartConfiguration.useKnowledgeSmartIntegration) {
                    localStorage.removeItem('[peak]ksAssessmentToTake');
                    return;
                }
                const peakEnabledSetting = settings.find(v => v.name === "PEAK_USER_ENABLED");
                let isPeakEnabled = peakEnabledSetting && peakEnabledSetting.settingValue === 1;

                if (!isPeakEnabled) {
                    localStorage.removeItem('[peak]ksAssessmentToTake');
                    this.navigateToKnowledgeSmartAssessment(ksAssessmentData.ksInviteId);
                }
            }
        })
    }

    navigateToKnowledgeSmartAssessment(ksInviteId: number) {
        // NOTE: This is based on the function navigateToKnowledgesmartAssessment in Peak.
        let ksUserId;
        this.APIv2.getAssessmentUserFromEmail(this.email).pipe(
            tap(ksUser => ksUserId = ksUser.userID),
            concatMap(ksUser => this.APIv2.requestLoginToken(ksUser.userID)),
            take(1),
        ).subscribe(token => {
            if (ksUserId) {
                let ksLink: string = `https://online.knowledgesmart.net/trafficcop.aspx?userid=${ksUserId}&logintoken=${token}&destination=taketest&inviteID=${ksInviteId}`;
                window.open(ksLink, '_blank').focus();
            }
        });
    }

    getContentTypeAndFormClassicRedirectUrl(contentId: string): Observable<string> {
        return this.pinnacleService.GetContentItem(contentId).pipe(
            map(content => this.convertContentTypeToClassicUrl(contentId, content.contentType)),
            catchError(() => of("/home")),
            take(1)
        );
    }

    convertContentTypeToClassicUrl(contentId: string, contentType: ContentType_T): string {
        let classicPartialUrl = "";

        switch (contentType) {
            case ContentType_T.cheatsheet:
                classicPartialUrl = `/cheatsheetviewer?id=${contentId}`;
                break;
            case ContentType_T.learningpath:
                classicPartialUrl = `/learningcenter/series?learningPathId=${contentId}`;
                break;
            case ContentType_T.course:
                classicPartialUrl = `/learningcenter/series?courseId=${contentId}`;
                break;
            case ContentType_T.workflow:
                classicPartialUrl = `/workflowviewer?id=${contentId}&contentType=workflow`;
            case ContentType_T.process:
                classicPartialUrl = `/workflowviewer?id=${contentId}&contentType=process`;
                break;
            case ContentType_T.task:
                classicPartialUrl = `/workflowviewer?id=${contentId}&contentType=task`;
                break;
            case ContentType_T.step:
                classicPartialUrl = `/workflowviewer?id=${contentId}&contentType=step`;
                break;
            case ContentType_T.video:
                classicPartialUrl = `/videoviewer?id=${contentId}`;
                break;
        }

        return classicPartialUrl;
    }
}


