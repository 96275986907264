import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule} from '@angular/core';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { RouterModule } from '@angular/router';


import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown' 
import { MyDatePickerModule } from 'mydatepicker';
import { DpDatePickerModule } from 'ng2-date-picker';
 
import { AppComponent } from './app.component'; 
import { LoginComponent } from './login/login.component';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { HomeComponent } from './home/home.component';
import { ExternalHomeComponent } from './external-home/external-home.component';
import { WorkcenterComponent } from './workcenter/workcenter.component';
import { Workcenter2Component } from './workcenter2/workcenter2.component';
import { LearningcenterComponent } from './learningcenter/learningcenter.component';
import { SearchComponent } from './search/search.component';

// Reusable Components
import { RecommendComponent } from './templates/recommend/recommend.component';
import { ShareComponent } from './templates/share/share.component';
import { ShareDropdownComponent } from './templates/share-dropdown/share-dropdown.component';
import { RelatedworkflowsComponent } from './templates/relatedworkflows/relatedworkflows.component';
import { RelatedCoursesComponent } from './templates/relatedcourses/relatedcourses.component';
import { CommentsComponent } from './templates/comments/comments.component';
import { ExpertsbuttonComponent } from './templates/expertsbutton/expertsbutton.component';
import { SyllabustemplateComponent } from './templates/learningcenterclasses/syllabustemplate/syllabustemplate.component';
import { SeriesheaderComponent } from './templates/learningcenterclasses/seriesheader/seriesheader.component';
import { LearningCenterCourseViewerComponent} from './learningcentercourseviewer/learningcentercourseviewer.component';
import { WorkgroupCardComponent } from './templates/workgroup-card/workgroup-card.component';
import { WorkgroupCardSmallComponent } from './templates/workgroup-card-small/workgroup-card-small.component';
import { EnrollmentCardComponent } from './templates/enrollment-card/enrollment-card.component';
import { DiagramViewComponent } from './templates/diagram-view/diagram-view.component';
import { AssetTitlebarComponent } from './templates/asset-titlebar/asset-titlebar.component';
import { UploadImageComponent } from './templates/uploadimage/uploadimage.component';
import { ChangePasswordComponent } from './templates/changepassword/changepassword.component';
import { ModalMessageComponent } from './modalmessage/modalmessage.component';
import { NotificationsComponent } from './templates/notifications/notifications.component';

import { VideoviewerComponent } from './videoviewer/videoviewer.component';
import { BrandingComponent } from './branding/branding.component';
import { CheatsheetviewerComponent } from './cheatsheetviewer/cheatsheetviewer.component';
import { ChatviewerComponent } from './chatviewer/chatviewer.component';
import { WorkflowviewerComponent } from './workflowviewer/workflowviewer.component';
import { LearningcenterseriesComponent } from './learningcenterseries/learningcenterseries.component';
import { ProfileComponent } from './profile/profile.component';
import { PinnacleLiteComponent } from './pinnaclelite/pinnaclelite.component';
import { PartnerPageComponent} from './partner/partner.component';
import { FrequentCategoryComponent } from './templates/frequent-category/frequent-category.component';
import { CourseViewerSyllabusComponent } from './templates/courseviewersyllabus/courseviewersyllabus.component';
 
import { LearningpageoutletComponent } from './learningpageoutlet/learningpageoutlet.component';
import { ChatComponent } from './chat/chat.component';
import { CheatsheetcontentComponent } from './templates/cheatsheetcontent/cheatsheetcontent.component';
import { VideoContentComponent } from './templates/videocontent/videocontent.component';
import { BentleyContentComponent } from './templates/bentleycontent/bentleycontent.component';
import { WorkflowContentComponent } from './templates/workflowcontent/workflowcontent.component';
import { TrainingClassContentComponent } from './templates/trainingclasscontent/trainingclasscontent.component';
import { QuizContentComponent } from './templates/quizcontent/quizcontent.component';

import { LinkIntercept } from './LinkIntercept';
import { ContentHtmlDirective } from './ContentHtmlDirective';
import { ModalHtmlDirective } from './ModalHtmlDirective';
import { CheatSheetModalComponent } from './templates/cheatsheetmodal/cheatsheetmodal.component';
import { VideoModalComponent } from './templates/videomodal/videomodal.component';
import { SafeHtmlPipe } from './SafeHTMLPipe'
import { ResizeTextDirective } from './ResizeTextDirective';

import { FilterPipe } from './pipes/filter.pipe';
import { TranslationPipe } from './pipes/TranslationPipe';
import { TruncatePipe } from './pipes/TruncatePipe';
import { ProductListingComponent } from './product-listing/product-listing.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { OfflineViewerComponent } from './offlineviewer/offlineviewer.component';
import { CorporatepanelComponent } from './templates/corporatepanel/corporatepanel.component';
import { ProfilepanelComponent } from './templates/profilepanel/profilepanel.component';
import { PlaylistpanelComponent } from './templates/playlistpanel/playlistpanel.component';
import { AddonspanelComponent } from './templates/addonspanel/addonspanel.component';
import { PartnerEditCustomerComponent } from './templates/partnereditcustomer/partnereditcustomer.component';
import { PartnerNewCustomerComponent } from './templates/partnernewcustomer/partnernewcustomer.component';
import { AuthenticationReponsePageComponent } from './authentication-reponse-page/authentication-reponse-page.component'; 
import { LoginuitilityComponent } from './loginuitility/loginuitility.component';
import { NewmodifycheatsheetComponent } from './templates/newmodifycheatsheet/newmodifycheatsheet.component';
import { ClientList } from './templates/clientlist/clientlist.component'
import { WorkflowtreeComponent } from './templates/workflowtree/workflowtree.component';
import { PasswordresetComponent } from './passwordreset/passwordreset.component';
import { SearchresultComponent } from './templates/searchresult/searchresult.component';
import { CompanybrandingComponent } from './companybranding/companybranding.component';
import { PinnacleLiteManageUsersComponent } from './pinnaclelite-manageusers/pinnaclelite-manageusers.component';
import { PinnacleLiteNewUserComponent } from './templates/pinnaclelite-newuser/pinnaclelite-newuser.component';
import { ColorPickerModule } from '../assets/scripts/ngx-color-picker';
import { WorkgroupExternalUsersComponent } from './templates/workgroupexternalusers/workgroupexternalusers';
import { WorkgroupSelfInviteComponent } from './templates/workgroup-selfinvite/workgroup-selfinvite.component';
import { WorkgroupCardNavigationComponent } from './templates/workgroup-card-navigation/workgroup-card-navigation.component';
import { WorkgroupAssetViewComponent } from './templates/workgroup-asset-view/workgroup-asset-view.component';
import { WorkgroupMemberViewComponent } from './templates/workgroup-member-view/workgroup-member-view.component';
import { WorkgroupDiscussionViewComponent } from './templates/workgroup-discussion-view/workgroup-discussion-view.component';

import { LiveEventCalendarComponent } from '../app/liveeventcalendar/liveeventcalendar.component';
import { UpcomingEventsPanelComponent } from '../app/templates/upcomingeventspanel/upcomingeventspanel.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WidgetContainerComponent } from './templates/widget-container/widget-container.component';
import { WidgetComponent } from './templates/widget/widget.component';
import { TestWidgetComponent } from './templates/widgets/test-widget/test-widget.component';
import { SecondTestWidgetComponent } from './templates/widgets/second-test-widget/second-test-widget.component';
import { ImageWidgetComponent } from './templates/widgets/image-widget/image-widget.component';
import { MyAssetsWidgetComponent } from './templates/widgets/my-assets-widget/my-assets-widget.component';
import { WidgetconfigComponent } from './templates/widgetconfig/widgetconfig.component';
import { MyWorkgroupsWidgetComponent } from './templates/widgets/my-workgroups-widget/my-workgroups-widget.component';
import { PersonalResourceAccessWidgetComponent } from './templates/widgets/personal-resource-access-widget/personal-resource-access-widget.component'
import { DxButtonModule, DxSchedulerModule, DxChartModule, DxPieChartModule, DxDataGridModule, DxDateBoxModule } from 'devextreme-angular';
import { ProfilePanelWidgetComponent } from './templates/widgets/profile-panel-widget/profile-panel-widget.component';
import { DownloadAddonsWidgetComponent } from './templates/widgets/download-addons-widget/download-addons-widget.component';
import { PartnerNewsWidgetComponent } from './templates/widgets/partner-news-widget/partner-news-widget.component';
import { CompanyNewsWidgetComponent } from './templates/widgets/company-news-widget/company-news-widget.component';
import { AssetLibraryWidgetComponent } from './templates/widgets/asset-library-widget/asset-library-widget.component';
import { SearchWidgetComponent } from './templates/widgets/search-widget/search-widget.component';
import { MyCoursesWidgetComponent } from './templates/widgets/my-courses-widget/my-courses-widget.component';
import { FrequentlyUsedWidgetComponent } from './templates/widgets/frequently-used-widget/frequently-used-widget.component';
import { TrendingWidgetComponent } from './templates/widgets/trending-widget/trending-widget.component';

import { LiveEventRegistrationForm } from '../app/templates/liveeventregistrationform/liveeventregistrationform.component';

import { Workcenter3Component } from '../app/workcenter3/workcenter3.component';
import { WorkCenterCardComponent } from '../app/templates/workcenter-card/workcenter-card.component';
import { LiveEventSurveyComponent } from '../app/externalpages/liveeventsurvey/liveeventsurvey.component';
import { LiveEventPropertiesComponent } from '../app/templates/liveeventproperties/liveeventproperties.component';
import { LiveEventRegistrationComponent } from '../app/externalpages/liveeventregistration/liveeventregistration.component';
import { StarRatingComponent } from '../app/templates/starrating/starrating.component';
import { RssFeedWidgetComponent } from '../app/templates/widgets/rss-feed-widget/rss-feed-widget.component';
import { PageLayoutsComponent } from '../app/page-layouts/page-layouts.component';

import { PublicWorkgroupsComponent } from '../app/templates/widgets/public-workgroups-widget/public-workgroups-widget.component';
import { PinnacleDateTimePipe } from '../app/pipes/pinnacleDateTime';
import { CanDeactivateGuard } from './services/can-deactivate.guard';
import { EnrollmentHistoryWidgetComponent } from '../app/templates/widgets/enrollment-history-widget/enrollment-history-widget.component';
import { ExternalLearningRecordsWidgetComponent } from '../app/templates/widgets/external-learning-records-widget/external-learning-records-widget.component';
import { RelatedLearningWidgetComponent} from '../app/templates/widgets/related-learning-widget/related-learning-widget.component';

import { PublicWorkgroupCardComponent } from '../app/templates/public-workgroup-card/public-workgroup-card.component'
import { EnrollmentCardSmallComponent } from './templates/enrollment-card-small/enrollment-card-small.component';
import { EnrollmentCardNavigationComponent } from './templates/enrollment-card-navigation/enrollment-card-navigation.component';
import { EnrollUsersContentComponent } from './templates/enrolluserscontent/enrolluserscontent.component';
import { LiveEventsWidgetComponent } from './templates/widgets/live-events-widget/live-events-widget.component';

import { ExternalUserCardComponent } from './templates/external-user-card/external-user-card.component';
import { EmbedWebpageWidgetComponent } from './templates/widgets/embed-webpage-widget/embed-webpage-widget.component';
import { ButtonPanelWidgetComponent } from './templates/widgets/button-panel-widget/button-panel-widget.component';
import { WorkgroupAssetUsageWidgetComponent } from './templates/widgets/workgroup-asset-usage-widget/workgroup-asset-usage-widget.component';
import { WorkgroupCourseCompletionWidgetComponent } from './templates/widgets/workgroup-course-completion-widget/workgroup-course-completion-widget.component';
import { APICacheService } from './services/api-cache.service';
import { ScormViewer } from './templates/scormviewer/scormviewer.component';
import { ProdGenApi } from './apiService/prodgen.api';
import { UserDataGridComponent } from './templates/userdatagrid/userdatagrid.component';
import { UserSelectionDataGridComponent } from './templates/userselectiondatagrid/userselectiondatagrid.component'
import { UserDataGridService } from './templates/userdatagrid/userdatagrid.service';
import { CanDeactivateLearningCenterCourseViewer } from './templates/scormviewer/scormviewer.guard';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { AssociatedLearningWidgetComponent } from './templates/widgets/associated-learning-widget/associated-learning-widget.component';
import { Apiv2Service } from './apiService/apiv2.service';
import { JobRoleDetailsComponent } from './job-role-details/job-role-details.component';
import { KSAssessmentNavbarComponent } from '../app/templates/ks-assessment-navbar/ks-assessment-navbar.component';
import { SessionTimeoutService } from './services/session-timeout.service';
import { SharedService } from './apiService/loginservice.service';
import { ZendeskRedirectComponent } from './zendesk_redirect/zendesk_redirect.component';
import { WorkgroupAcceptInvite } from '../app/externalpages/workgroupacceptinvite/workgroupacceptinvite.component';
import { SubmitExternalLearningRecord } from './externalpages/submit-external-learning-record/submit-external-learning-record.component';
import { DynamicActivityInputFieldComponent } from './templates/external-learning/dynamic-attribute-input-field/dynamic-attribute-input-field.component';
import { EpPercentageInputFieldComponent } from './components/input-fields/percentage-input-field/percentage-input-field.component';
import { EpStarRatingComponent } from './components/star-rating/star-rating.component';
import { EpStarRatingInputFieldComponent } from './components/input-fields/star-rating-input-field/star-rating-input-field.component';
import { FileUploadButtonComponent } from './templates/file-upload-button/file-upload-button.component';
import { EpTextInputFieldComponent } from './components/input-fields/text-input-field/text-input-field.component';
import { PeakRedirectGuard } from './services/peak-redirect.guard';
import { ContentUrlService } from './services/content-url.service';
import { AIChatContainerComponent } from './aichat/aichat-container/aichat-container.component';
import { AIChatCitationComponent } from './aichat/aichat-citation/aichat-citation.component';
import { AIChatMessageComponent } from './aichat/aichat-message/aichat-message.component';
import { AIChatPromptComponent } from './aichat/aichat-prompt/aichat-prompt.component';
import { MarkdownModule } from 'ngx-markdown';
import { environment } from 'src/environments/environment';
import * as LogRocket from 'logrocket';
import * as packageJson from '../../package.json';

function initializeLogging() {
  return () => {
      if (environment.logRocketAppId && window.location.hostname !== 'localhost') {
        const version = `Classic-${packageJson.version}`;
        LogRocket.init(environment.logRocketAppId, {
            network: {
                requestSanitizer: request => {
                    if (
                        request.url.toLowerCase().indexOf('/assets/') !== -1 ||
                        request.url.toLowerCase().endsWith('.svg') ||
                        request.url.toLowerCase().endsWith('.css') ||
                        request.url.toLowerCase().endsWith('.js') ||
                        request.url.toLowerCase().endsWith('.ico')
                    ) {
                        return null;
                    }

                    delete request.headers['authorization'];
                    delete request.headers['apiKey'];
                    delete request.headers['userAccessKey'];
                    delete request.headers['apiV2AccessKey'];

                    if (
                        request.url.toLowerCase().indexOf('/authorization') !== -1 ||
                        request.url.toLowerCase().indexOf('/users/enduser/password') !== -1
                    ) {

                        request.body = null;
                    }

                    return request;
                },
            },
            release: version,
        });
        LogRocket.track('code', { release: version });
      }
  };
}

@NgModule({
  declarations: [
      AppComponent, LoginComponent, HomeComponent, WorkcenterComponent, Workcenter2Component, 
      LearningcenterComponent,  SearchComponent, VideoviewerComponent,
      CheatsheetviewerComponent, WorkflowviewerComponent, RecommendComponent, ShareComponent, ShareDropdownComponent, RelatedworkflowsComponent,
      CommentsComponent,    ExpertsbuttonComponent,
       LearningcenterseriesComponent,  SyllabustemplateComponent, RelatedCoursesComponent,
      SeriesheaderComponent,  ProfileComponent, FrequentCategoryComponent, LearningCenterCourseViewerComponent,
        CourseViewerSyllabusComponent, LearningpageoutletComponent,
      WorkgroupCardComponent, WorkgroupCardSmallComponent, EnrollmentCardComponent, DiagramViewComponent, AssetTitlebarComponent, ChatComponent, CheatsheetcontentComponent,
      VideoContentComponent, TrainingClassContentComponent, WorkflowContentComponent, QuizContentComponent, ContentHtmlDirective, LinkIntercept, UploadImageComponent, ChangePasswordComponent, ModalMessageComponent, 
      BentleyContentComponent, 
      
      NotificationsComponent, CheatSheetModalComponent, ModalHtmlDirective, VideoModalComponent, SafeHtmlPipe, ChatviewerComponent, ResizeTextDirective, TranslationPipe, TruncatePipe, ProductListingComponent, PlaylistComponent, OfflineViewerComponent, CorporatepanelComponent, ProfilepanelComponent, PlaylistpanelComponent, AddonspanelComponent, PinnacleLiteComponent, PartnerPageComponent, WorkflowtreeComponent, PasswordresetComponent,
      PartnerEditCustomerComponent, PartnerNewCustomerComponent, AuthenticationReponsePageComponent, LoginuitilityComponent, NewmodifycheatsheetComponent, FilterPipe, SearchresultComponent, BrandingComponent, CompanybrandingComponent, PinnacleLiteManageUsersComponent, PinnacleLiteNewUserComponent, DashboardComponent, WidgetContainerComponent, WidgetComponent, TestWidgetComponent, SecondTestWidgetComponent, ImageWidgetComponent, MyAssetsWidgetComponent, WidgetconfigComponent,
      WorkgroupExternalUsersComponent, ExternalHomeComponent,
      WorkgroupCardNavigationComponent, WorkgroupAssetViewComponent, WorkgroupMemberViewComponent,
      WorkgroupSelfInviteComponent,
      LiveEventCalendarComponent, UpcomingEventsPanelComponent, LiveEventRegistrationForm,
      LiveEventSurveyComponent, LiveEventRegistrationComponent, LiveEventPropertiesComponent,
      MyWorkgroupsWidgetComponent, PersonalResourceAccessWidgetComponent,
      ProfilePanelWidgetComponent, DownloadAddonsWidgetComponent, PartnerNewsWidgetComponent, CompanyNewsWidgetComponent, AssetLibraryWidgetComponent, SearchWidgetComponent, MyCoursesWidgetComponent, FrequentlyUsedWidgetComponent, TrendingWidgetComponent,
      EnrollmentHistoryWidgetComponent,
      ExternalLearningRecordsWidgetComponent,
      RelatedLearningWidgetComponent,
      WorkgroupCardNavigationComponent, WorkgroupAssetViewComponent, WorkgroupMemberViewComponent, WorkgroupDiscussionViewComponent, ClientList, RssFeedWidgetComponent, PageLayoutsComponent, Workcenter3Component, WorkCenterCardComponent,
      StarRatingComponent, PublicWorkgroupsComponent,
      PinnacleDateTimePipe, PublicWorkgroupCardComponent, EnrollmentCardSmallComponent,EnrollmentCardNavigationComponent,
      EnrollUsersContentComponent, LiveEventsWidgetComponent,
      EmbedWebpageWidgetComponent,
      ButtonPanelWidgetComponent,
      ExternalUserCardComponent,
      WorkgroupAssetUsageWidgetComponent,
      WorkgroupCourseCompletionWidgetComponent,
      ScormViewer,
      UserDataGridComponent,
      UserSelectionDataGridComponent,
      LandingpageComponent,
      AssociatedLearningWidgetComponent,
      JobRoleDetailsComponent,
        KSAssessmentNavbarComponent,
      ZendeskRedirectComponent,
        WorkgroupAcceptInvite,
        SubmitExternalLearningRecord,
        DynamicActivityInputFieldComponent,
        EpPercentageInputFieldComponent,
        EpStarRatingComponent,
        EpStarRatingInputFieldComponent,
        FileUploadButtonComponent,
        EpTextInputFieldComponent,
        NavigationMenuComponent,
        AIChatContainerComponent,
        AIChatCitationComponent,
        AIChatMessageComponent,
        AIChatPromptComponent
  ],
  imports: [
      BrowserModule, HttpClientModule, FormsModule, AngularMultiSelectModule, MyDatePickerModule, DpDatePickerModule,
      BrowserAnimationsModule, ColorPickerModule, DxButtonModule, DxSchedulerModule, DxChartModule, DxPieChartModule, DxSchedulerModule,
      DxDataGridModule, DxDateBoxModule, ReactiveFormsModule, MarkdownModule.forRoot(),
    RouterModule.forRoot([
        { path: '', redirectTo: '/home', pathMatch: 'full' }, 
        { path: 'login', component: LoginComponent},
        { path: 'home', component: HomeComponent},
        { path: 'external-home', component: ExternalHomeComponent },
        { path: 'workcenter', component: Workcenter3Component},
        //{ path: 'workcenter', component: WorkcenterComponent },
        { path: 'learningcenter', component: LearningpageoutletComponent,
          children: [
            { path: '', component: LearningcenterComponent},
            { path: 'series', component: LearningcenterseriesComponent, canActivate: [PeakRedirectGuard]},
            {
                path: 'courseviewer',
                component: LearningCenterCourseViewerComponent,
                canActivate: [PeakRedirectGuard],
                canDeactivate: [CanDeactivateLearningCenterCourseViewer]
            },
          ]
        },
        { path: 'profile', component: ProfileComponent},
        { path: 'search', component: SearchComponent},
        { path: 'videoviewer', component: VideoviewerComponent, canActivate: [PeakRedirectGuard]},
        { path: 'cheatsheetviewer', component: CheatsheetviewerComponent, canActivate: [PeakRedirectGuard]},
        { path: 'workflowviewer', component: WorkflowviewerComponent, canActivate: [PeakRedirectGuard]},
        { path: 'chatviewer', component: ChatviewerComponent }, 
        { path: 'product-listing', component: ProductListingComponent },
        { path: 'myassets', component: PlaylistComponent },
        { path: 'offlineviewer', component: OfflineViewerComponent },
        { path: 'authentication', component: AuthenticationReponsePageComponent },
        { path: 'pinnaclelite', component: PinnacleLiteComponent },
        { path: 'partner', component: PartnerPageComponent },
        { path: 'passwordreset', component: PasswordresetComponent },
        { path: 'companybranding', component: CompanybrandingComponent, canDeactivate: [CanDeactivateGuard] },
        { path: 'pl-manageusers', component: PinnacleLiteManageUsersComponent },
        { path: 'liveevents', component: LearningcenterComponent },
        { path: 'dashboard', component: DashboardComponent },
        { path: 'pagelayouts', component: PageLayoutsComponent },
        { path: 'liveeventsurvey', component: LiveEventSurveyComponent },
        { path: 'liveeventregistration', component: LiveEventRegistrationComponent },
        { path: 'liveeventviewer', component: LiveEventRegistrationComponent },
        { path: 'scormpreview', component: ScormViewer },
        { path: 'landingpage', component: LandingpageComponent },
        { path: 'jobroledetails', component: JobRoleDetailsComponent },
        { path: 'zendesk', component: ZendeskRedirectComponent },
        { path: 'workgroupinvite', component: WorkgroupAcceptInvite },
        { path: 'submitexternallearningrecord', component: SubmitExternalLearningRecord },
        { path: 'aichat', component: AIChatContainerComponent },


    ]),

  ],
    providers: [AppComponent, { provide: LocationStrategy, useClass: HashLocationStrategy }, CanDeactivateGuard, PeakRedirectGuard,
        APICacheService, ProdGenApi, UserDataGridService,
        CanDeactivateLearningCenterCourseViewer, Apiv2Service,
        SessionTimeoutService, SharedService, ContentUrlService,
        {
          provide: APP_INITIALIZER,
          useFactory: initializeLogging,
          multi: true,
        }
    ],
    entryComponents: [TestWidgetComponent, SecondTestWidgetComponent, ImageWidgetComponent, MyAssetsWidgetComponent, MyWorkgroupsWidgetComponent,
        PersonalResourceAccessWidgetComponent, DownloadAddonsWidgetComponent, ProfilePanelWidgetComponent, SearchWidgetComponent, MyCoursesWidgetComponent, FrequentlyUsedWidgetComponent,
        TrendingWidgetComponent, PartnerNewsWidgetComponent, CompanyNewsWidgetComponent, AssetLibraryWidgetComponent, RssFeedWidgetComponent,
        EnrollmentHistoryWidgetComponent,
        ExternalLearningRecordsWidgetComponent,
        PublicWorkgroupsComponent,
        RelatedLearningWidgetComponent,
        LiveEventsWidgetComponent,
        EmbedWebpageWidgetComponent,
        ButtonPanelWidgetComponent,
        WorkgroupAssetUsageWidgetComponent,
        WorkgroupCourseCompletionWidgetComponent,
        AssociatedLearningWidgetComponent
    ],
    
    bootstrap: [AppComponent] 
  
})
export class AppModule { }
