import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import {ActivatedRoute} from "@angular/router";
import { MarkdownService } from 'ngx-markdown';
import { ChatMessage, ChatCitation, ChatContextMessage } from '../models/aichat';
import { AIChatService } from '../../services/aichat.service';
import { ProdGenApi } from '../../apiService/prodgen.api';


@Component({
  selector: 'ep-aichat-container',
  templateUrl: './aichat-container.component.html',
  styleUrls: ['./aichat-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AIChatContainerComponent implements OnInit, OnDestroy {
  isAIChatAllowed = false;
  public chatMessages: ChatMessage[] = [];
  public chatMessagesOriginalFormat: ChatMessage[] = [];
  userImageUrl: string = "";

  form = new FormGroup({
    prompt: new FormControl('', null)
  });

    constructor(private route: ActivatedRoute,
        private apiv1Service: ProdGenApi,
        private aiChatService: AIChatService,
        private markdownService: MarkdownService,
        private cdr: ChangeDetectorRef) {
    
  }

    ngOnInit(): void {
        if (ProdGenApi.isPinnacleLite) {
            // AI Chat allowed for all Pinnacle Lite users
            this.isAIChatAllowed = true;
            this.cdr.detectChanges();
        }
        else {
            // Not Pinnacle Lite- so check if AI Chat is allowed for the user
            this.apiv1Service.getSettingByName('AI_CHAT_ENABLED').subscribe(res => {
                this.isAIChatAllowed = res.settingValue === 1;
                this.cdr.detectChanges();
            });
        }


        this.apiv1Service.getCurrentUser().subscribe(user => { 
            this.userImageUrl = user.imageURL;
        });
   }

  ngOnDestroy(): void {
  }

  onSendButtonClicked(prompt: string): void {

    const contextMessages: ChatContextMessage[] = [];

    for (const contextMessage of this.chatMessagesOriginalFormat) {
      contextMessages.push(new ChatContextMessage(contextMessage.message, contextMessage.isUser));
    }

    const userMessage = new ChatMessage(prompt, true);
    this.chatMessages.push(userMessage);
    this.chatMessagesOriginalFormat.push(userMessage);

    const copilotMessage = new ChatMessage('', false);
    this.chatMessages.push(copilotMessage);

    this.aiChatService.fetchChatResponse(prompt, contextMessages).subscribe(response => {
      let content = response.result.content;

      // capture the original format of the message - used for context
      this.chatMessagesOriginalFormat.push(new ChatMessage(content, false));

      content = this.markdownService.compile(content);


      let curCitation = 1;
      for (const citation of response.result.citations) {
        const url = citation.url;
        copilotMessage.citations.push(new ChatCitation(citation.title, url, citation.contentType));

        // look for this citation in the message and replace it with a link
        content = content.split(citation.referenceName).join('<sup class="superscript">' + curCitation + '</sup>');
        curCitation++; 
      }
      copilotMessage.message = content;

      this.cdr.detectChanges();
      this.scrollToEnd();
    });

    //this.form.get('prompt').setValue('');
    this.cdr.detectChanges();

    this.scrollToEnd();
  }

  scrollToEnd(): void {
    const element = document.getElementById("aichat-scrollable-content");
    element.scroll(0, element.scrollHeight);
  }


  onClearButtonClicked(): void {
    this.chatMessages = [];
    this.chatMessagesOriginalFormat = [];
    this.cdr.detectChanges();
  }

}



