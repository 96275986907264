import { Injectable } from '@angular/core';
import { ProdGenApi } from '../apiService/prodgen.api';
import { AppComponent } from '../app.component';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PinnacleLiteSettings, Setting } from '../apiService/classFiles/class.organizations';

@Injectable()
export class ContentUrlService {
    constructor(private pinnacleService: ProdGenApi) { }

    getPeakUrlPath(pinnacleLinkUrl: string) {
        if (pinnacleLinkUrl && pinnacleLinkUrl.length > 0)
        {
            const queryParams = this.extractQueryParams(pinnacleLinkUrl);
            
            if (pinnacleLinkUrl.includes('learningcenter/series?courseId')) {
                const courseId = queryParams.get('courseId');
                if (courseId) {
                    return `/content/${courseId}`;
                }
            }
            else if (pinnacleLinkUrl.includes('learningcenter/series?learningPathId')) {
                const learningPathId = queryParams.get('learningPathId');
                if (learningPathId) {
                    return `/content/${learningPathId}`;
                }
            }
            else if (pinnacleLinkUrl.includes('cheatsheetviewer') ||
                pinnacleLinkUrl.includes('workflowviewer') ||
                pinnacleLinkUrl.includes('videoviewer')) {
                const contentId = queryParams.get('id');
                if (contentId) {
                    return `/content/${contentId}`;
                }
            }
            else if (pinnacleLinkUrl.includes('/content/')) {
                return pinnacleLinkUrl;
            }
        }

        return null;
    }

    isRedirectUrlToClassicContent(pinnacleLinkUrl: string) {
        if (pinnacleLinkUrl && pinnacleLinkUrl.length > 0) {
            if (pinnacleLinkUrl.includes('learningcenter/series?courseId') ||
                pinnacleLinkUrl.includes('learningcenter/series?learningPathId') ||
                pinnacleLinkUrl.includes('cheatsheetviewer') ||
                pinnacleLinkUrl.includes('workflowviewer') ||
                pinnacleLinkUrl.includes('videoviewer')) {

                return true;
            }
        }
        return false;
    }

    isRedirectUrlToPeakContent(pinnacleLinkUrl: string) {
        if (pinnacleLinkUrl && pinnacleLinkUrl.length > 0) {
            if (pinnacleLinkUrl.includes('/content/')) {

                return true;
            }
        }
        return false;
    }

    routeToPeakUrlFromPinnacleUrl(pinnacleLinkUrl: string) {
        if (pinnacleLinkUrl && pinnacleLinkUrl.includes('learningcenter/courseviewer?enrollmentId')) {
            // Special case for enrollments (get the course id for the enrollment)
            this.routeToPeakCourseUrlFromEnrollmentUrl(pinnacleLinkUrl);
        }
        else {
            let peakUrlPath = this.getPeakUrlPath(pinnacleLinkUrl);
            localStorage.setItem("LoginURL", "");
            try {
                // send v2 bearer token & lang values to Peak to bypass authorization
                // for tenants with peak experience enabled
                AppComponent.thisFromOutside.openPeak(
                    '_self', 
                    {
                        bt: ProdGenApi.getAPIV2AccessKey().bearerToken,
                        lang: this.pinnacleService.getCurrentLanguage(),
                        peakUrlPath: peakUrlPath || ''
                    });
                sessionStorage.clear();
            } catch {}
        }       
    }

    routeToPeakCourseUrlFromEnrollmentUrl(enrollmentUrl: string) {
        if (enrollmentUrl) {
            const queryParams = this.extractQueryParams(enrollmentUrl);
            const enrollId = queryParams.get('enrollmentId');
            if (enrollId) {
                this.pinnacleService.GetEnrollment(enrollId).subscribe(res => {
                    if (res && res.courseId) {
                        try {
                            AppComponent.thisFromOutside.openPeak(
                                '_self', 
                                {
                                    bt: ProdGenApi.getAPIV2AccessKey().bearerToken,
                                    lang: this.pinnacleService.getCurrentLanguage(),
                                    peakUrlPath: `/content/${res.courseId}`
                                });
                            sessionStorage.clear();
                        } catch {}
                    }
                });
            }
        }
    }

    launchPortalBasedOnTenantPeakExperience() {
        //////////////////////////////////
        // THIS BLOCK IS TEMPORARY
        // Launch the Peak Experience in tandem with continuing with current (for developement)
        forkJoin([
            this.pinnacleService.GetCurrentTenantSettings().pipe(catchError(err => { return of(err); })),
            this.pinnacleService.GetCurrentTenantPinnacleLiteSetting().pipe(catchError(err => { return of(err); })),
        ]).subscribe({
            next: ([settings, pinnacleLiteSettings]: [Setting[], PinnacleLiteSettings]) => {
                if (!settings || settings.length === 0 || !pinnacleLiteSettings) {
                    return;
                }

                const peakEnabledSetting = settings.find(v => v.name === "PEAK_USER_ENABLED");
                const isPeakEnabled = peakEnabledSetting.settingValue === 1;

                if (isPeakEnabled) {
                    this.routeToPeakHomePage();
                } else {
                    window.location.href = "#/home";
                    window.location.reload();
                }
            }
        })
    }

    routeToPeakHomePage() {
        localStorage.setItem("LoginURL", "");
        try {
            // send v2 bearer token & lang values to Peak to bypass authorization
            // for tenants with peak experience enabled
            AppComponent.thisFromOutside.openPeak(
                '_self',
                {
                    bt: ProdGenApi.getAPIV2AccessKey().bearerToken,
                    lang: this.pinnacleService.getCurrentLanguage(),
                    peakUrlPath: ''
                });
            sessionStorage.clear();
        } catch { }
    }

    extractQueryParams = (url: string): Map<string, string> | null => {
        if (!url) {
          return null;
        }
      
        let queryParamsString = '';
        if (url.includes('?')) {
          queryParamsString = url.split('?')[1];
        } else {
          queryParamsString = url;
        }
      
        const queryParams = new Map<string, string>();
        if (queryParamsString) {
          queryParamsString.split('&').forEach((param) => {
            const [key, value] = param.split('=');
            queryParams.set(decodeURIComponent(key), decodeURIComponent(value));
          });
        }
      
        return queryParams;
      }
}